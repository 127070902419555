import { Link } from "react-router-dom";
import { IList } from "../../../utils/generalType";
import "../sharedLandingcss/Site.css";
import "../sharedLandingcss/Site_Animate.css";

import Slide from "react-reveal/Slide";

const whatWeDo: IList[] = [
  {
    title: "Crisis Management",
    caption:
      "Don't let a crisis define you. Protect your reputation with our crisis management service. Unlike the conventional response,",
    image: `${process.env.PUBLIC_URL + "/images/services/crisis1.png"}`,
    link: "/landing/services/1/Crisis_Management",
  },
  {
    title: "Reputation & Brand Boosting",
    caption:
      "Let LampHills help you build a powerful presence that resonates with your audience. Building a stand-out brand takes more than guesswork",
    image: `${process.env.PUBLIC_URL + "/images/services/brand1.png"}`,
    link: "/landing/services/2/Reputation_Brand_Boosting",
  },
  {
    title: "Launch PR/Event Management",
    caption:
      "Leave the ordinary behind and host unforgettable launches and events that will push your brand forward. ",
    image: `${process.env.PUBLIC_URL + "/images/services/pr1.png"}`,
    link: "/landing/services/3/Launch_PR_Event_Management",
  },
  {
    title: "Media Relations",
    caption:
      "Cut through the noise and get your story to the right audience with LampHills' media relations.. ",
    image: `${process.env.PUBLIC_URL + "/images/services/media1.png"}`,
    link: "/landing/services/4/Media_Relations",
  },
  {
    title: "Content Creation Package",
    caption:
      "Are you tired of bland content that gets ignored? Our content creation package is the solution for you. ",
    image: `${process.env.PUBLIC_URL + "/images/services/content1.png"}`,
    link: "/landing/services/5/Content_Creation_Package",
  },
  {
    title: "Press Releases and Speeches",
    caption:
      "Do you want powerful press releases and speeches that grab media attention and leave a memorable impression?",
    image: `${process.env.PUBLIC_URL + "/images/services/press1.png"}`,
    link: "/landing/services/6/Press_Releases_and_Speeches",
  },
];

export const ServiceListCard = () => {
  return (
    <>
      <div className="w3-col w3-padding">
        <div className="w3-col serviceBg w3-round-xlarge w3-padding">
          <Slide up>
            <div className="w3-large w3-col w3-padding">
              <b>Our Services</b>
            </div>
          </Slide>

          {whatWeDo.map((item: IList, index: number) => (
            <Slide up>
              <div className="w3-border-top w3-col w3-padding">
                <Link to={item.link || "/"}>{item.title}</Link>
              </div>
            </Slide>
          ))}
        </div>
      </div>
    </>
  );
};

export default ServiceListCard;
