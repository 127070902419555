import { Link, useParams } from "react-router-dom";
import { IList } from "../../../utils/generalType";
import "../sharedLandingcss/Site.css";
import "../sharedLandingcss/Site_Animate.css";
import PagerHeaderComp from "./PagesHeaderComp";
import ServiceListCard from "./Service_List_Card";
import Slide from "react-reveal/Slide";

const whatWeDo: IList[] = [
  {
    title: "Crisis Management",
    subTitle: "When Crises Strike, Your Reputation is Our Priority",
    caption:
      "Don't let a crisis define you. Protect your reputation with our crisis management service. Unlike the conventional response, we do not react to problems, rather we proactively assess risks to prepare you for anything. We act swiftly and strategically to minimize damage and protect your credibility during a crisis. We ensure your audience's trust remains unshaken. But don't wait until a crisis strikes; contact us now.",
    image: `${process.env.PUBLIC_URL + "/images/services/crisis1.png"}`,
    content: (
      <>
        <Slide up>
          <p className="w3-center">
            <img
              width="70%"
              src={`${process.env.PUBLIC_URL + "/images/services/crisis1.png"}`}
              alt="ima_"
            />
          </p>
        </Slide>
        <Slide left>
          <p className="fontJostRegular w3-medium">
            A crisis could happen anytime, threatening to hurt your image and
            the trust of the people who matter to you. We at LampHills know that
            how you act in these tough situations can make or break your brand's
            reputation. Our Crisis Management service is designed to protect you
            from possible threats, keep your reputation safe, and set you up for
            long-term success.
          </p>
          <p className="fontJostRegular w3-medium">
            Our approach goes further than typical reactionary steps. We conduct
            thorough risk assessments that find possible weak spots and help you
            make strong backup plans specific to your situation. This proactive
            approach keeps you from being caught off guard and lets you move
            quickly and decisively during a crisis.
          </p>
          <h2 className="fontJostBold w3-large">
            Rapid Response and Damage Control
          </h2>
          <p className="fontJostRegular w3-medium">
            When a crisis occurs, our experienced crisis managers jump into
            action with a well-thought-out plan to limit the damage and protect
            the integrity of you or your business. We shape the story by using
            our many media contacts and relations skills to ensure that your
            side of the story is heard and understood by everyone involved.
          </p>
          <h2 className="fontJostBold w3-large">
            Rebuilding your reputation and restoring trust
          </h2>

          <p className="w3-center">
            <img
              width="70%"
              src={`${process.env.PUBLIC_URL + "/images/services/crisis2.png"}`}
              alt="ima_"
            />
          </p>

          {/* Call to Action Section */}
          <p
            className="siteSecondaryyBg callToAction w3-round-large w3-center"
            style={{ marginTop: "50px" }}
          >
            <p className="w3-large fontJostBold w3-padding">
              Don't let a single misstep undo years of hard work and
              trust-building. Take a proactive stance and partner with LampHills
              for expert crisis management services.
            </p>
            <Link
              className="w3-btn  getStartedBtn serviceBtn fontJostRegular w3-blue w3-round-xxlarge w3-card-2 w3-margin-bottom"
              to={"/landing/contactus"}
            >
              <span> Get Started Now</span>
            </Link>
          </p>

          <p className="fontJostRegular w3-medium">
            Our team of seasoned professionals is standing by, ready to conduct
            comprehensive risk assessments, develop tailored contingency plans,
            and provide rapid response solutions to minimize damage and protect
            your credibility.
          </p>

          <p className="fontJostRegular w3-medium">
            Waiting until a crisis unfolds can be costly and devastating. Secure
            your brand's future by acting now.
            <ul>
              <li>Take the First Step Towards Crisis Preparedness</li>
              <li>
                Schedule a risk assessment consultation with our crisis
                management experts
              </li>
              <li>
                Receive a personalized crisis response strategy tailored to your
                unique needs
              </li>
              <li>
                Gain peace of mind knowing you're prepared for any potential
                threats
              </li>
            </ul>
          </p>

          {/* Call to Action Section */}
          <p
            className="siteSecondaryyBg callToAction w3-round-large w3-center"
            style={{ marginTop: "50px" }}
          >
            <p className="w3-large fontJostBold w3-padding">
              Your reputation is too valuable to leave to chance. Contact us
              today and let LampHills be your trusted partner in navigating even
              the stormiest of situations. With our proactive approach and
              strategic guidance, you can weather any crisis and emerge
              stronger, more resilient, and better positioned for long-term
              success.
            </p>
            <Link
              className="w3-btn  getStartedBtn serviceBtn fontJostRegular w3-blue w3-round-xxlarge w3-card-2 w3-margin-bottom"
              to={"/landing/contactus"}
            >
              <span> Get Quote</span>
            </Link>
          </p>
        </Slide>
      </>
    ),
  },
  {
    title: "Reputation & Brand Boosting",
    subTitle:
      "Elevate Your Brand to New Heights with Reputation & Brand Boosting",
    caption:
      "Let LampHills help you build a powerful presence that resonates with your audience. Building a stand-out brand takes more than guesswork, so we leverage data and insights to create a unique strategy that connects with your target audience. We'll help you create a narrative that leaves a lasting impression and sets you apart from the competition. So, don’t struggle alone; partner with LampHills and watch your brand propel forward!",
    image: `${process.env.PUBLIC_URL + "/images/services/brand1.png"}`,
    content: (
      <>
        <Slide up>
          <p className="w3-center">
            <img
              width="70%"
              src={`${process.env.PUBLIC_URL + "/images/services/brand1.png"}`}
              alt="ima_"
            />
          </p>
        </Slide>
        <Slide left>
          <p className="fontJostRegular w3-medium">
            A great product or service is no longer enough to captivate your
            target audience. Building a powerful brand presence that resonates
            and leaves a lasting impression is crucial for standing out from the
            competition and fostering enduring customer loyalty
          </p>
          <p className="fontJostRegular w3-medium">
            At LampHills, we understand that effective brand building is both an
            art and a science. It's not about guesswork or generic strategies.
            It's about crafting a unique narrative that speaks directly to your
            audience's needs, values, and aspirations.
          </p>
          <p className="fontJostRegular w3-medium">
            Our Reputation & Brand Boosting service is driven by data-driven
            insights and a deep understanding of consumer behaviour. We conduct
            comprehensive market research, analyze industry trends, and delve
            into your target audience's psychographics, ensuring that every
            aspect of your brand strategy is tailored to resonate with the right
            people at the right time.
          </p>

          <p className="fontJostRegular w3-medium">
            Through strategic storytelling and innovative marketing campaigns,
            we'll help you craft a compelling brand narrative that sets you
            apart from the competition. We'll leverage a multi-channel approach,
            seamlessly integrating traditional and digital platforms to amplify
            your brand's voice and establish a powerful presence across various
            touchpoints.
          </p>

          <p className="fontJostRegular w3-medium">
            But building a strong brand is more than just crafting a narrative;
            it's about fostering enduring connections with your audience. Our
            team of experts will work closely with you to develop a robust
            reputation management strategy, proactively monitoring and
            addressing any potential threats or negative sentiment, ensuring
            that your brand's image remains impeccable.
          </p>

          {/* <p className="fontJostRegular w3-medium">
            Don't leave your brand's reputation and growth to chance. Partner
            with LampHills and unlock the full potential of your brand.
          </p> */}

          {/* Call to Action Section */}
          <p
            className="siteSecondaryyBg callToAction w3-round-large w3-center"
            style={{ marginTop: "50px" }}
          >
            <p className="w3-large fontJostBold w3-padding">
              Don't leave your brand's reputation and growth to chance. Partner
              with LampHills and unlock the full potential of your brand.
            </p>
            <Link
              className="w3-btn  getStartedBtn serviceBtn fontJostRegular w3-blue w3-round-xxlarge w3-card-2 w3-margin-bottom"
              to={"/landing/contactus"}
            >
              <span> Get Started Now</span>
            </Link>
          </p>

          <p className="fontJostRegular w3-medium">
            Our Reputation & Brand Boosting experts are ready to embark on a
            transformative journey with you, leveraging data-driven insights,
            strategic storytelling, and innovative marketing campaigns to
            elevate your brand to new heights.
            <ul>
              <li>Take the First Step Towards Brand Excellence</li>
              <li>Schedule a comprehensive brand audit and consultation</li>
              <li>
                Receive a customized brand strategy tailored to your unique
                needs
              </li>
              <li>
                Gain access to our suite of reputation management tools and
                resources
              </li>
            </ul>
          </p>

          <p className="fontJostRegular w3-medium">
            With LampHills as your partner, you'll benefit from our unwavering
            commitment to excellence, our deep understanding of consumer
            behavior, and our proven track record of delivering tangible results
            for our clients.
          </p>

          {/* Call to Action Section */}
          <p
            className="siteSecondaryyBg callToAction w3-round-large w3-center"
            style={{ marginTop: "50px" }}
          >
            <p className="w3-large fontJostBold w3-padding">
              Don't settle for a mediocre brand presence. Contact us today and
              let our Reputation & Brand Boosting service propel your brand
              towards lasting success, captivating your audience and leaving an
              indelible mark in your industry.
            </p>
            <Link
              className="w3-btn  getStartedBtn serviceBtn fontJostRegular w3-blue w3-round-xxlarge w3-card-2 w3-margin-bottom"
              to={"/landing/contactus"}
            >
              <span> Get Quote</span>
            </Link>
          </p>
        </Slide>
      </>
    ),
  },
  {
    title: "Launch PR/Event Management",
    subTitle:
      "Where Brands Take Center Stage: Unforgettable Launches and Events",
    caption:
      "Leave the ordinary behind and host unforgettable launches and events that will push your brand forward. Our launch and event management services turn that dream into reality. We create experiences that put you and your brand in the spotlight, generating excitement and pushing your brand story forward. Ready to host unforgettable launches and events? Get a quote!",
    image: `${process.env.PUBLIC_URL + "/images/services/pr1.png"}`,
    content: (
      <>
        <Slide up>
          <p className="w3-center">
            <img
              width="70%"
              src={`${process.env.PUBLIC_URL + "/images/services/pr1.png"}`}
              alt="ima_"
            />
          </p>
        </Slide>
        <Slide left>
          <p className="fontJostRegular w3-medium">
            A successful launch or event can put your business in the spotlight,
            creating excitement, making connections that last, and leaving a
            lasting impression on the people you want to reach. At LampHills, we
            know that these important times call for more than just logistical
            know-how. They call for a strategic vision, unwavering attention to
            detail, and a never-ending drive for excellence.
          </p>
          <p className="fontJostRegular w3-medium">
            Our Launch PR/Event Management service aims to go beyond the
            ordinary and create unique experiences that fascinate and inspire.
            We plan events and build immersive brand narratives that connect
            every part of your launch or event, from the look and feel to the
            interactive elements and hands-on activities.
          </p>
          <p className="fontJostRegular w3-medium">
            With the help of a team of experienced event planners and creative
            thinkers, we carefully plan every detail to ensure your launch or
            event is a huge success. Our team does everything they can to ensure
            everything goes perfectly, from coming up with amazing themes and
            experiences to booking fancy venues and managing complicated
            details.
          </p>

          <p className="fontJostRegular w3-medium">
            But our help isn't just for the event. We use our many media
            contacts and public relations knowledge to spread the word about
            your personal or company brand, building energy and anticipation
            before the event and ensuring that the effects and excitement last
            long after the curtains come down. We make sure that your launch or
            event has the most impact and reach possible by using smart media
            placements, working with influencers, and targeted marketing
            campaigns. This will move your brand forward and solidify its place
            in the minds of your target audience.
          </p>
          {/* 
          <p className="fontJostRegular w3-medium">
            Our Launch PR/Event Management professionals are prepared to
            collaborate with you, using their creative abilities and strategic
            knowledge to organize remarkable launches and events that generate
            enthusiasm, cultivate long-term relationships, and enhance your
            brand's reputation.
          </p> */}

          {/* Call to Action Section */}
          <p
            className="siteSecondaryyBg callToAction w3-round-large w3-center"
            style={{ marginTop: "50px" }}
          >
            <p className="w3-large fontJostBold w3-padding">
              Our Launch PR/Event Management professionals are prepared to
              collaborate with you, using their creative abilities and strategic
              knowledge to organize remarkable launches and events that generate
              enthusiasm, cultivate long-term relationships, and enhance your
              brand's reputation.
            </p>
            <Link
              className="w3-btn  getStartedBtn serviceBtn fontJostRegular w3-blue w3-round-xxlarge w3-card-2 w3-margin-bottom"
              to={"/landing/contactus"}
            >
              <span> Get Started Now</span>
            </Link>
          </p>

          <p className="fontJostRegular w3-medium">
            Choose LampHills as your partner and experience our unwavering
            commitment to excellence, meticulous attention to detail, and proven
            track record of delivering unforgettable brand experiences that
            leave a lasting impact.
          </p>

          {/* Call to Action Section */}
          <p
            className="siteSecondaryyBg callToAction w3-round-large w3-center"
            style={{ marginTop: "50px" }}
          >
            <p className="w3-large fontJostBold w3-padding">
              Why settle for average launches and events? Get in touch with us
              now and experience the power of our Launch PR/Event Management
              service. We'll help your brand make a lasting impact, captivating
              your audience and propelling your brand narrative forward in
              remarkable
            </p>
            <Link
              className="w3-btn  getStartedBtn serviceBtn fontJostRegular w3-blue w3-round-xxlarge w3-card-2 w3-margin-bottom"
              to={"/landing/contactus"}
            >
              <span> Get Quote</span>
            </Link>
          </p>
        </Slide>
      </>
    ),
  },
  {
    title: "Media Relations",
    subTitle: "Amplify Your Brand's Voice with Strategic Media Relations",
    caption:
      "Cut through the noise and get your story to the right audience with LampHills' media relations.. Our vast network of journalists and influencers across diverse platforms isn't just for show. We leverage these connections to secure the headlines that matter most, amplifying your message and reaching the audience you need to propel your brand forward. Don't be invisible. Let LampHills amplify your story.",
    image: `${process.env.PUBLIC_URL + "/images/services/media1.png"}`,
    content: (
      <>
        <Slide up>
          <p className="w3-center">
            <img
              width="70%"
              src={`${process.env.PUBLIC_URL + "/images/services/media1.png"}`}
              alt="ima_"
            />
          </p>
        </Slide>
        <Slide left>
          <p className="fontJostRegular w3-medium">
            Our Media Relations service is guided by a comprehensive knowledge
            of the constantly changing media industry and an extensive network
            of influential journalists, bloggers, and thought leaders across
            various platforms. We prioritize building strong, enduring
            relationships with our clients, based on trust, expertise, and a
            mutual dedication to producing top-notch, influential content.
          </p>
          <p className="fontJostRegular w3-medium">
            By using a combination of thoughtfully selected media placements,
            focused outreach campaigns, and strategic collaborations, we
            guarantee that your brand's narrative will effectively reach its
            intended audience precisely when it matters most. We specialize in
            creating impactful media materials such as pitches and thought
            leadership content. Our team of experts ensures that these materials
            are engaging and resonate with your target audience, establishing
            your brand as a trusted authority in your industry.
          </p>
          <p className="fontJostRegular w3-medium">
            However, our service extends beyond conventional media platforms. We
            understand the importance of using digital platforms and influencer
            marketing to enhance your brand's message and establish genuine
            connections with your audience. By forming strategic partnerships
            and collaborations, we establish a mutually beneficial connection
            between your brand and influential voices, guaranteeing optimal
            visibility and interaction.
          </p>

          {/* <p className="fontJostRegular w3-medium">
            Don't let your brand's story go unheard. Partner with LampHills and
            unlock the power of strategic media relations.
          </p> */}

          {/* Call to Action Section */}
          <p
            className="siteSecondaryyBg callToAction w3-round-large w3-center"
            style={{ marginTop: "50px" }}
          >
            <p className="w3-large fontJostBold w3-padding">
              Don't let your brand's story go unheard. Partner with LampHills
              and unlock the power of strategic media relations.
            </p>
            <Link
              className="w3-btn  getStartedBtn serviceBtn fontJostRegular w3-blue w3-round-xxlarge w3-card-2 w3-margin-bottom"
              to={"/landing/contactus"}
            >
              <span> Get Started Now</span>
            </Link>
          </p>

          <p className="fontJostRegular w3-medium">
            With LampHills as your partner, you'll benefit from our deep
            understanding of the media landscape, our cultivated relationships
            with influential voices, and our track record of delivering
            impactful media placements that drive tangible results.
          </p>

          {/* Call to Action Section */}
          <p
            className="siteSecondaryyBg callToAction w3-round-large w3-center"
            style={{ marginTop: "50px" }}
          >
            <p className="w3-large fontJostBold w3-padding">
              Elevate your brand's narrative and leave a lasting impression.
              Contact us today and let our Media Relations service amplify your
              voice, captivate your audience, and propel your brand to new
              heights.
            </p>
            <Link
              className="w3-btn  getStartedBtn serviceBtn fontJostRegular w3-blue w3-round-xxlarge w3-card-2 w3-margin-bottom"
              to={"/landing/contactus"}
            >
              <span> Get Quote</span>
            </Link>
          </p>
        </Slide>
      </>
    ),
  },
  {
    title: "Content Creation Package",
    subTitle: "Captivate Your Audience with Engaging, Results-Driven Content",
    caption:
      "Are you tired of bland content that gets ignored? Our content creation package is the solution for you. We create engaging blog posts, social media graphics, and more designed to resonate with your audience and drive real results. We don't just tell your brand story, we bring it to life with creativity, authenticity, and an eye for impact. Get ready to watch conversations explode, and conversions soar. Ready to stop the scroll and start a movement? Let's chat about your content strategy now.",
    image: `${process.env.PUBLIC_URL + "/images/services/content1.png"}`,
    content: (
      <>
        <Slide up>
          <p className="w3-center">
            <img
              width="70%"
              src={`${
                process.env.PUBLIC_URL + "/images/services/content1.png"
              }`}
              alt="ima_"
            />
          </p>
        </Slide>
        <Slide left>
          <p className="fontJostRegular w3-medium">
            Creating effective content necessitates a combination of imaginative
            ideas, thoughtful analysis, and a steadfast dedication to
            excellence.
          </p>
          <p className="fontJostRegular w3-medium">
            Our Content Creation service is crafted to enhance your brand's
            storytelling and spark meaningful conversations that connect with
            your target audience. We focus on creating compelling narratives
            that engage, motivate, and leave a memorable impact. Our team of
            skilled professionals collaborates seamlessly to create engaging
            blog posts, insightful articles, captivating social media visuals,
            and multimedia content that effectively showcases your brand's
            narrative.
          </p>
          <p className="fontJostRegular w3-medium">
            Our content is carefully crafted to align with your audience's
            preferences, interests, and online behaviour, thanks to our thorough
            research and data-driven insights. We take a comprehensive approach
            to ensure that our efforts deliver tangible outcomes that align with
            your business goals. This includes increasing website traffic,
            nurturing leads, and establishing your brand as a trusted authority
            in the industry.
          </p>

          <p className="fontJostRegular w3-medium">
            However, creating content goes beyond a mere transaction. It
            requires a collaborative partnership built on trust and a thorough
            comprehension of your brand's distinct voice and values. Our team
            works closely with your brand to capture its essence and maintain
            consistent messaging across all platforms and channels.
          </p>

          {/* <p className="fontJostRegular w3-medium">
            Don't let your brand's narrative get lost in the digital noise.
            Partner with LampHills and experience the transformative power of
            captivating content.
          </p> */}

          {/* Call to Action Section */}
          <p
            className="siteSecondaryyBg callToAction w3-round-large w3-center"
            style={{ marginTop: "50px" }}
          >
            <p className="w3-large fontJostBold w3-padding">
              Don't let your brand's narrative get lost in the digital noise.
              Partner with LampHills and experience the transformative power of
              captivating content.
            </p>
            <Link
              className="w3-btn  getStartedBtn serviceBtn fontJostRegular w3-blue w3-round-xxlarge w3-card-2 w3-margin-bottom"
              to={"/landing/contactus"}
            >
              <span> Get Started Now</span>
            </Link>
          </p>

          <p className="fontJostRegular w3-medium">
            With LampHills as your partner, you'll benefit from our creative
            prowess, strategic insights, and commitment to delivering content
            that resonates, engages, and drives measurable results for your
            brand.
          </p>
          {/* Call to Action Section */}
          <p
            className="siteSecondaryyBg callToAction w3-round-large w3-center"
            style={{ marginTop: "50px" }}
          >
            <p className="w3-large fontJostBold w3-padding">
              Ignite conversations, captivate your audience, and propel your
              brand forward. Contact us today and let our Content Creation
              Package unleash the full potential of your brand's narrative.
            </p>
            <Link
              className="w3-btn  getStartedBtn serviceBtn fontJostRegular w3-blue w3-round-xxlarge w3-card-2 w3-margin-bottom"
              to={"/landing/contactus"}
            >
              <span> Get Quote</span>
            </Link>
          </p>
        </Slide>
      </>
    ),
  },
  {
    title: "Press Releases and Speeches",
    subTitle: "Craft Compelling Narratives That Captivate and Inspire",
    caption:
      "Do you want powerful press releases and speeches that grab media attention and leave a memorable impression? Use our services to ensure your message is clear, concise, and strategically delivered, shaping public perception, resonating with audiences, and solidifying your standing in your desired space. Don't just make a statement; start a movement. Let LampHills write your story. Contact us today!",
    image: `${process.env.PUBLIC_URL + "/images/services/press1.png"}`,
    content: (
      <>
        <Slide up>
          <p className="w3-center">
            <img
              width="70%"
              src={`${
                process.env.PUBLIC_URL + "/images/services/press1.png?2"
              }`}
              alt="ima_"
            />
          </p>
        </Slide>
        <Slide left>
          <p className="fontJostRegular w3-medium">
            At LampHills, our Press Releases and Speeches service is designed to
            assist you in honing your communication skills. We aim to help your
            message stand out, shape public opinion, and establish your
            authority as a thought leader in your chosen field.
          </p>
          <p className="fontJostRegular w3-medium">
            Our team of experienced writers and communication experts is
            well-versed in the art of creating compelling narratives and
            persuasive arguments. We collaborate closely with you to simplify
            your vision into a straightforward, concise, and persuasive story
            that engages your audience and motivates them to take action.
          </p>
          <p className="fontJostRegular w3-medium">
            We specialize in creating captivating headlines, developing engaging
            storylines, and incorporating the perfect blend of expertise,
            timeliness, and significance to capture the attention of the media
            and secure valuable coverage.
          </p>

          <p className="fontJostRegular w3-medium">
            When it comes to speeches, we recognize the significance of
            effective communication to inspire, motivate, and make a lasting
            impression on your audience. Our writers carefully choose each word,
            incorporating persuasive techniques and impactful messaging that
            connects with your audience on both an emotional and intellectual
            level, guaranteeing that your speech leaves a lasting impression.
          </p>

          <p className="fontJostRegular w3-medium">
            However, our service extends beyond just writing. We offer expert
            advice on crafting your message, delivering it effectively, and
            getting it in front of the right people at the right moment, using
            the most impactful methods.
          </p>

          <p className="fontJostRegular w3-medium">
            If you need to create excitement for a new product, handle a crisis,
            or gain support for a cause, our Press Releases and Speeches service
            can help you create compelling stories that engage, motivate, and
            make a lasting impression on your audience.
          </p>

          <p className="fontJostRegular w3-medium">
            Captivate, inspire, and leave a lasting impression. Contact us today
            and let our Press Releases and Speeches service elevate your brand's
            storytelling to new heights.
          </p>

          {/* Call to Action Section */}
          <p
            className="siteSecondaryyBg callToAction w3-round-large w3-center"
            style={{ marginTop: "50px" }}
          >
            <p className="w3-large fontJostBold w3-padding">
              Captivate, inspire, and leave a lasting impression. Contact us
              today and let our Press Releases and Speeches service elevate your
              brand's storytelling to new heights.
            </p>
            <Link
              className="w3-btn  getStartedBtn serviceBtn fontJostRegular w3-blue w3-round-xxlarge w3-card-2 w3-margin-bottom"
              to={"/landing/contactus"}
            >
              <span> Get Quote </span>
            </Link>
          </p>

          {/* <p style={{ marginTop: "50px" }}>
            <Link
              className="w3-btn  getStartedBtn serviceBtn fontJostRegular w3-blue w3-round-xxlarge w3-card-2 w3-margin-bottom"
              to={"/landing/contactus"}
            >
              <span> Contact Us </span>
            </Link>
          </p> */}
        </Slide>
      </>
    ),
  },
];

export const ServiceDetails = () => {
  const params = useParams();
  console.log(params?.id);
  const SelectedIndex =
    Number(params?.id) < 7 ? Number(params?.id || 1) - Number(1) : 1;

  return (
    <>
      <PagerHeaderComp
        title={whatWeDo[SelectedIndex].title}
        subTitle={whatWeDo[SelectedIndex].subTitle}
      ></PagerHeaderComp>
      {/* Our Service Details */}
      <div className="w3-content">
        <div className="w3-white w3-col howTo">
          {/* <div
            className="w3-col l4 s12 m6 w3-center"
            style={{ padding: "5px" }}
          >
            <img
              className="w3-margin-top w3-margin-bottom w3-round"
              src={whatWeDo[SelectedIndex].image}
              alt="image_"
              style={{
                maxWidth: "90%",
                width: "250px",
              }}
            />
          </div> */}

          <div
            className="w3-col l8 s12 m8"
            style={{ padding: "5px", paddingTop: "70px", lineHeight: "32px" }}
          >
            {whatWeDo[SelectedIndex].content}
          </div>

          <div
            className="w3-col l4 s12 m4"
            style={{ padding: "5px", paddingTop: "70px", lineHeight: "32px" }}
          >
            <ServiceListCard></ServiceListCard>
          </div>
        </div>
      </div>
      {/* Our Service Details */}
      {/* <ServiceSlide></ServiceSlide> */}
    </>
  );
};

export default ServiceDetails;
