import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { IList } from "../../../utils/generalType";
import "../sharedLandingcss/Site.css";
import "../sharedLandingcss/Site_Animate.css";
import Slide from "react-reveal/Slide";

export const TrustedBrandComp = () => {
  const trustedClient: IList[] = [
    {
      image: `${process.env.PUBLIC_URL + "/images/brands/brand1.jpeg"}`,
    },
    { image: `${process.env.PUBLIC_URL + "/images/brands/brand2.jpeg"}` },
    { image: `${process.env.PUBLIC_URL + "/images/brands/brand3.jpeg"}` },
    { image: `${process.env.PUBLIC_URL + "/images/brands/brand1.jpeg"}` },
    { image: `${process.env.PUBLIC_URL + "/images/brands/brand2.jpeg"}` },
    { image: `${process.env.PUBLIC_URL + "/images/brands/brand3.jpeg"}` },
  ];
  return (
    <>
      {" "}
      {/* Trusted */}
      <div className="trustedBg w3-col ">
        <div className="w3-content">
          <Slide up>
            <div className="w3-col w3-center">
              <p> Trusted by:</p>
            </div>
            <div className="w3-col">
              <Swiper
                // install Swiper modules
                modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
                onSlideChange={() => console.log("slide change")}
                onSwiper={(swiper) => console.log(swiper)}
                spaceBetween={5}
                slidesPerView={4}
                breakpoints={{
                  320: {
                    slidesPerView: 2,
                    spaceBetween: 5,
                  },
                  // when window width is >= 600x
                  600: {
                    slidesPerView: 3,
                    spaceBetween: 5,
                  },
                  // when window width is >= 840px
                  840: {
                    slidesPerView: 4,
                    spaceBetween: 5,
                  },
                }}
              >
                {trustedClient.map((item: IList, index: number) => (
                  <SwiperSlide>
                    <div className="w3-col" style={{ padding: "5px" }}>
                      <div className="w3-col w3-padding">
                        <img
                          className="w3-margin-top w3-margin-bottom w3-round-large"
                          src={item.image}
                          alt="image_"
                          style={{ maxWidth: "100%" }}
                        />
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </Slide>
        </div>
      </div>
      {/* Trusted */}
    </>
  );
};

export default TrustedBrandComp;
