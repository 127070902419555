import "../sharedLandingcss/Site.css";
import "../sharedLandingcss/Site_Animate.css";

export const MotionCompHeroBottom = () => {
  return (
    <>
      {" "}
      <div style={{ height: "40px", position: "relative" }}>
        <span className="moveHero_7">
          <img
            style={{ maxWidth: "80%", width: "30px" }}
            alt="heroImage"
            src={`${process.env.PUBLIC_URL + "/images/homePage/img-7.png"}`}
          />{" "}
          &nbsp;
        </span>

        <span className="moveHero_8">
          <img
            style={{ maxWidth: "80%", width: "30px" }}
            alt="heroImage"
            src={`${process.env.PUBLIC_URL + "/images/homePage/img-8.png"}`}
          />{" "}
          &nbsp;
        </span>

        <span className="moveHero_9">
          <img
            style={{ maxWidth: "80%", width: "100px" }}
            alt="heroImage"
            src={`${process.env.PUBLIC_URL + "/images/homePage/img-19.png"}`}
          />{" "}
          &nbsp;
        </span>

        <span className="moveHero_10">
          <img
            style={{ maxWidth: "80%", width: "100px" }}
            alt="heroImage"
            src={`${process.env.PUBLIC_URL + "/images/homePage/img-17.png"}`}
          />{" "}
          &nbsp;
        </span>

        <span className="moveHero_11">
          <img
            style={{ maxWidth: "80%", width: "100px" }}
            alt="heroImage"
            src={`${process.env.PUBLIC_URL + "/images/homePage/img-10.png"}`}
          />{" "}
          &nbsp;
        </span>

        <span className="moveHero_12">
          <img
            style={{ maxWidth: "80%", width: "100px" }}
            alt="heroImage"
            src={`${process.env.PUBLIC_URL + "/images/homePage/img-11.png"}`}
          />{" "}
          &nbsp;
        </span>

        <br />
      </div>
    </>
  );
};

export default MotionCompHeroBottom;
