import { Link } from "react-router-dom";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="w3-col footerBg w3-white" style={{ paddingTop: "50px" }}>
      <div className="w3-content">
        <div
          style={{
            paddingTop: "50px",
            paddingBottom: "50px",
            minHeight: "200px",
          }}
          className="w3-col w3-padding"
        >
          <div className="w3-col l4 m4 s12" style={{ padding: "10px" }}>
            <span className="logoTextFooter">
              <img
                style={{ maxHeight: "40px", width: "40%" }}
                className="favicon-header"
                src={`${process.env.PUBLIC_URL + "/images/logo.png"}`}
                alt=""
              />
            </span>

            <p className="briefAbout" style={{ paddingTop: "20px" }}>
              LampHills is a premier PR agency that helps brands and individuals
              shine. We aim to help you create a positive image, manage crises
              with strategic expertise, and strengthen stakeholder
              relationships...
            </p>

            <p style={{ paddingTop: "20px" }}>
              {/* <span>
                <img
                  style={{ width: "36px", maxWidth: "100%" }}
                  alt="logo"
                  src={process.env.PUBLIC_URL + "/images/footericonig.svg"}
                />
                &nbsp; &nbsp;
              </span> */}
              <span>
                <a
                  href="https://x.com/lamphills"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <img
                    style={{ width: "36px", maxWidth: "100%" }}
                    alt="logo"
                    src={
                      process.env.PUBLIC_URL + "/images/homePage/twitter.svg"
                    }
                  />
                  &nbsp; &nbsp;
                </a>
              </span>
              <span>
                <a
                  href="https://www.facebook.com/lamphills?mibextid=LQQJ4d"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <img
                    style={{ width: "36px", maxWidth: "100%" }}
                    alt="logo"
                    src={
                      process.env.PUBLIC_URL + "/images/homePage/facebook.svg"
                    }
                  />
                  &nbsp; &nbsp;
                </a>
              </span>
              <span>
                <a
                  href="https://www.linkedin.com/company/lamphills/"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <img
                    style={{ width: "36px", maxWidth: "100%" }}
                    alt="logo"
                    src={
                      process.env.PUBLIC_URL + "/images/homePage/linkedin.svg"
                    }
                  />
                  &nbsp;
                </a>
              </span>
            </p>
          </div>

          <div className="w3-col l8 m8 s12">
            <div className="w3-col l6 m6 s12" style={{ padding: "10px" }}>
              <h3 className="footerHeader"> Services</h3>
              <Link
                className="footerLink"
                to="/landing/services/1/Crisis_Management"
              >
                Crisis Management
              </Link>
              <br />
              <Link
                className="footerLink"
                to="/landing/services/2/Reputation_Brand_Boosting"
              >
                Reputation & Brand Boosting
              </Link>
              <br />
              <Link
                className="footerLink"
                to="/landing/services/3/Launch PR-Event Management"
              >
                Launch PR/Event Management
              </Link>
              <br />
              <Link
                className="footerLink"
                to="/landing/services/4/Reputation_Brand_Boosting"
              >
                Media Relations
              </Link>
              <br />
              <Link
                className="footerLink"
                to="/landing/services/5/Content_Creation_Package"
              >
                Content Creation Package
              </Link>
              <br />
              <Link
                className="footerLink"
                to="/landing/services/6/Press_Releases_and_Speeches"
              >
                Press Releases and Speeches
              </Link>
              <br />
            </div>

            <div className="w3-col l6 m6 s12" style={{ padding: "10px" }}>
              <h3 className="footerHeader"> Contacts </h3>
              <Link className="footerLink w3-col" to="/">
                <span className="w3-col l5 s5 m5"> Phone: </span>
                <span className="w3-col l7 s7 m7">+234 704 390 7787</span>
              </Link>
              <br />
              <Link className="footerLink w3-col" to="/">
                <span className="w3-col l5 s5 m5">Email :</span>
                <span className="w3-col l7 s7 m7">contact@lamphills.com</span>
              </Link>
              <br />
              <Link className="footerLink" to="/">
                <span className="w3-col l5 s5 m5">Address:</span>
                <span className="w3-col l7 s7 m7">
                  10 Mast Street, Barking, London, United Kingdom
                </span>
              </Link>
              <br />

              <h3 className="footerHeader"> Terms </h3>
              <Link className="footerLink" to="/landing/terms_and_condition">
                Terms And Condition
              </Link>
              <br />
              <Link className="footerLink" to="/landing/privacy_policy">
                Privacy Policy
              </Link>
            </div>
          </div>
        </div>

        <div
          style={{
            paddingTop: "50px",
            paddingBottom: "20px",
            minHeight: "60px",
          }}
          className="w3-col w3-border-top"
        >
          <p className="copyRightText">
            © Copyright 2024 © All rights reserved
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
