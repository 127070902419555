import "../sharedLandingcss/Site.css";
import "../sharedLandingcss/Site_Animate.css";
import Slide from "react-reveal/Slide";

type IPagerHeaderComp = {
  title?: string;
  subTitle?: string;
};

export const PagerHeaderComp: React.FC<IPagerHeaderComp> = ({
  title,
  subTitle,
}) => {
  return (
    <>
      {/* Header Title */}
      <Slide up>
        <div className="pageTitleHeaderBg w3-col w3-center ">
          <div className="w3-content w3-padding">
            <div>
              <h2 className="fontJostBold w3-xxlarge">{title}</h2>
              <p className="fontJostRegular w3-large">{subTitle}</p>
            </div>
          </div>
        </div>
      </Slide>
      {/* Header Title */}
    </>
  );
};

export default PagerHeaderComp;
