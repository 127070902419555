import { IList } from "../../../utils/generalType";
import "../sharedLandingcss/Site.css";
import "../sharedLandingcss/Site_Animate.css";
import MotionCompGeneral from "./Motion_Comp";
import Slide from "react-reveal/Slide";

export const CoreValueComp = () => {
  const coreValue: IList[] = [
    {
      title: "Creativity",
      caption:
        "Creativity fuels innovation and drives us to think outside the box. We embrace creativity as a catalyst for finding unique solutions, telling compelling stories, and captivating audiences.",
      image: `${process.env.PUBLIC_URL + "/images/homePage/core1.png"}`,
    },
    {
      title: "Professionalism",
      caption:
        "We maintain the highest standards of professionalism in all our interactions. Our highly skilled and experienced team is dedicated to delivering exceptional service with a polished and efficient approach.",
      image: `${process.env.PUBLIC_URL + "/images/homePage/core2.png"}`,
    },
    {
      title: "Collaborative Environment",
      caption:
        "We nurture a team environment where open communication, knowledge sharing, and mutual respect are valued. By working together seamlessly, we harness our team's collective strengths to deliver outstanding results for our clients.",
      image: `${process.env.PUBLIC_URL + "/images/homePage/core3.png"}`,
    },
    {
      title: "Exceptional Results",
      caption:
        "Exceptional results are our ultimate goal. We go above and beyond to deliver outcomes that exceed expectations and drive success for our partners, employees, and clients.",
      image: `${process.env.PUBLIC_URL + "/images/homePage/core4.png"}`,
    },
  ];
  return (
    <>
      {/* Core Value  */}
      <div className="w3-white growTrafficBg w3-col w3-center ">
        {/* Motions for Traffic */}
        <div className="w3-hide-small w3-hide-medium">
          <MotionCompGeneral
            showMoveLeft={true}
            showMoveRight={true}
            showRotateRight={true}
            rotateRightImg={`${
              process.env.PUBLIC_URL + "/images/homePage/spec-el-2.png"
            }`}
          ></MotionCompGeneral>
        </div>
        <div className="w3-content w3-padding">
          <Slide up>
            <h2 className="fontJostBold w3-xxlarge">Our Core Values</h2>
            <p className="fontJostRegular w3-medium">
              Our core values are the foundation of everything we do. They guide
              our actions, decisions, and interactions with clients, partners,
              and team members.
            </p>
          </Slide>
          <div></div>

          <div className="w3-col">
            {coreValue.map((item: IList, index: number) => (
              <Slide up>
                <div className="w3-col l6  m6 s12" style={{ padding: "15px" }}>
                  <div className="w3-col w3-padding w3-center">
                    <img
                      className=""
                      src={item.image}
                      alt="image_"
                      style={{ maxWidth: "60%", width: "80px" }}
                    />
                    <h4 className="fontJostBold w3-large">{item.title}</h4>
                    <p className="fontJostRegular w3-medium">{item.caption}</p>
                  </div>
                </div>
              </Slide>
            ))}
          </div>
        </div>
      </div>
      {/* Core Value  */}
    </>
  );
};

export default CoreValueComp;
