import "../sharedLandingcss/Site.css";
import "../sharedLandingcss/Site_Animate.css";
import ServiceDetails from "../sharedLandingcomponents/Service_Details_Comp";

const ServiceDetailsPage = () => {
  return (
    <div className="w3-white w3-col" style={{ minHeight: "400px" }}>
      <ServiceDetails></ServiceDetails>
    </div>
  );
};

export default ServiceDetailsPage;
