import "../sharedLandingcss/Site.css";
import "../sharedLandingcss/Site_Animate.css";
import Slide from "react-reveal/Slide";

export const MissionComp = () => {
  return (
    <>
      {/* Mission Statement */}
      <div className="workTogetherBg w3-col w3-center ">
        <div className="w3-content w3-padding">
          <Slide up>
            <div>
              <h2 className="fontJostBold w3-xxlarge">Mission Statement</h2>
            </div>

            <p className="fontJostRegular w3-medium">
              LampHills aims to redefine reputation management, empowering
              individuals and brands to create narratives with purpose,
              authenticity, and impact. <br />
              We believe every story deserves to be told with integrity and
              resonance, using innovative strategies and creativity to navigate
              the public space.
            </p>

            <p className="fontJostRegular w3-medium">
              Our commitment to professionalism, exceptional results, and
              transparency is at the core of our approach, which is a
              client-centric approach where success is a shared journey.
              <br /> We are more than just a service provider; we are your
              partners in progress and well-curated perceptions.
            </p>
          </Slide>
        </div>
      </div>
      {/*  Mission Statement */}
    </>
  );
};

export default MissionComp;
