import { Link } from "react-router-dom";
import "../sharedLandingcss/Site.css";
import "../sharedLandingcss/Site_Animate.css";
import MotionCompHeroBottom from "./Motion_Comp_Hero_Down";
import MotionCompHeroTop from "./Motion_Comp_Hero_Top";
import Slide from "react-reveal/Slide";

export const HeroComp = () => {
  return (
    <>
      {/* Hero Section */}
      <div className="heroSectionbg w3-col">
        <div className="w3-content">
          <div className="w3-container">
            <div className="w3-col l5 s12 m5">
              <Slide left>
                <h2 className="heroHeaderText">
                  Be  Seen <br />
                  <span> How & </span>
                  <span className="heroHeaderTextPurple"> where </span>
                  <br /> <span> You Want</span>
                </h2>
                <p className="herocontent">
                  We understand the power of strategic public
                  relations in amplifying your personal and company brand
                  presence while resonating with your target audience.
                  <br />
                </p>
                {/* <p className="fontJostRegular w3-medium">
                  LampHills is a premier PR agency that helps brands and
                  individuals shine. We aim to help you create a positive image,
                  manage crises with strategic expertise, and strengthen
                  stakeholder relationships to maintain a strong reputation,
                  visibility, credibility, and trust among your target audience.
                </p>

                <p className="fontJostRegular w3-medium">
                  Our expertise spans crisis management, reputation building,
                  event planning, media relations, content creation, and more.
                </p>

                <p className="fontJostRegular w3-medium">
                  At LampHills, we specialize in sculpting and maintaining the
                  public image of businesses and individuals alike.
                </p> */}

                <p style={{ marginTop: "50px" }}>
                  <Link
                    className="w3-btn  getStartedBtn heroBtn fontJostRegular w3-blue w3-round-xxlarge w3-card-2 w3-margin-bottom"
                    to={"/landing/contactus"}
                  >
                    <span>GET STARTED </span>
                  </Link>
                  &nbsp;
                  <Link
                    className="w3-btn w3-white fontJostRegular heroBtn w3-round-xxlarge w3-card-2 w3-margin-bottom"
                    to={"/landing/about"}
                  >
                    <span> ABOUT US</span>
                  </Link>
                </p>
              </Slide>
            </div>

            <div className="w3-col l7 s12 m7 w3-center">
              <Slide up>
                {/* First Set Of Hero Motions */}
                <MotionCompHeroTop></MotionCompHeroTop>

                {/* Center Image */}
                <img
                  style={{ maxWidth: "80%", width: "400px" }}
                  alt="heroImage"
                  src={`${
                    process.env.PUBLIC_URL + "/images/homePage/heropic.png"
                  }`}
                />

                {/* Second Set Of Hero Motions */}
                <MotionCompHeroBottom></MotionCompHeroBottom>
              </Slide>
            </div>
          </div>
        </div>
      </div>
      {/* End Hero Section */}
    </>
  );
};

export default HeroComp;
