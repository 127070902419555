import { useRef } from "react";
import useScrollTriggeredCountUp from "../../../hooks/useScrollTriggeredCountUp";
import { IList } from "../../../utils/generalType";
import "../sharedLandingcss/Site.css";
import "../sharedLandingcss/Site_Animate.css";

export const CounterComp = () => {
  const ref = useRef<HTMLDivElement>(null);
  const count_1 = useScrollTriggeredCountUp(ref, 11);
  const count_2 = useScrollTriggeredCountUp(ref, 50);
  const count_3 = useScrollTriggeredCountUp(ref, 117);
  const count_4 = useScrollTriggeredCountUp(ref, 21);

  const counters: IList[] = [
    { title: "Experience", subTitle: "YEARS", value: count_1 },
    { title: "Clients", subTitle: "SATISFIED", value: count_2 },
    { title: "Finish", subTitle: "PROJECT", value: count_3 },
    { title: "Partnerships", subTitle: "STRATEGIC", value: count_4 },
  ];

  return (
    <>
      {/* Our number */}
      <div className="ourStats w3-text-white w3-col w3-center ">
        <div className="w3-content">
          <div className="w3-col w3-center">
            {counters.map((item: IList, index: number) => (
              <>
                <div className="w3-col l3 s12 m12" style={{ padding: "5px" }}>
                  <div className="w3-col">
                    <div className="w3-col">
                      <div ref={ref} className="statNumber fontJostBold">
                        {item.value}
                      </div>
                    </div>
                    <div className="w3-col">
                      <span className="statSubTitle fontJostRegular">
                        {item.subTitle}
                      </span>
                      <br />
                      <span className="statTitle fontJostBold">
                        {item.title}
                      </span>
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
      {/* Our number */}
    </>
  );
};

export default CounterComp;
