import "../sharedLandingcss/Site.css";
import "../sharedLandingcss/Site_Animate.css";
import PagerHeaderComp from "../sharedLandingcomponents/PagesHeaderComp";
import MotionCompGeneral from "../sharedLandingcomponents/Motion_Comp";
import OurTeamComp from "../sharedLandingcomponents/our_Team_Comp";
import ServiceSlide from "../sharedLandingcomponents/Service_Slide_Comp";
import TestimonyComp from "../sharedLandingcomponents/Testimony_Comp";
import Slide from "react-reveal/Slide";
import { IList } from "../../../utils/generalType";

const AboutPage = () => {
  const counters: IList[] = [
    { title: "Experience", subTitle: "YEARS", value: 11 },
    { title: "Clients", subTitle: "SATISFIED", value: 50 },
    { title: "Finish", subTitle: "PROJECT", value: 117 },
    { title: "Partnerships", subTitle: "STRATEGIC", value: 21 },
  ];
  return (
    <div className="w3-white w3-col" style={{ minHeight: "400px" }}>
      <PagerHeaderComp
        title="About Us"
        subTitle=" Illuminating Your Path to Success"
      ></PagerHeaderComp>

      {/* Content  */}
      <div className="w3-white otherContentBg w3-col">
        {/* Motions for Traffic */}
        <div className="w3-hide-small w3-hide-medium">
          <MotionCompGeneral
            showMoveLeft={true}
            showMoveRight={true}
            showRotateRight={true}
            rotateRightImg={`${
              process.env.PUBLIC_URL + "/images/homePage/spec-el-2.png"
            }`}
          ></MotionCompGeneral>
        </div>

        <div className="w3-content w3-padding">
          {/* first Row */}
          <div className="w3-col">
            <div className="w3-col l6 m6 s12" style={{ padding: "5px" }}>
              <Slide up>
                <h2 className="fontJostBold w3-xxlarge">
                  At LampHills, we believe in crafting stories that resonate and
                  reputations that shine.
                </h2>
                <p className="fontJostRegular w3-medium">
                  Founded on the principles of transparency, creativity, and
                  strategic insight, LampHills is your go-to if you're seeking
                  to enhance your public image and media relations. Our team of
                  seasoned experts is committed to guiding you through every
                  step of your PR journey, ensuring that your message not only
                  reaches but also positively impacts your target audience.
                </p>
              </Slide>
            </div>

            <div className="w3-col l6 m6 s12" style={{ padding: "5px" }}>
              {counters.map((item: IList, index: number) => (
                <div
                  className="w3-col l6 s6 m6 w3-center"
                  style={{ marginBottom: "45px" }}
                >
                  <Slide up>
                    <h2 className="fontJostBold heroHeaderTextPurple w3-xxlarge">
                      {item.value}
                    </h2>
                    <span className="fontJostBold w3-large">
                      {item.subTitle}{" "}
                    </span>{" "}
                    <br />
                    <span className="fontJostBold w3-medium">{item.title}</span>
                  </Slide>
                </div>
              ))}
            </div>
          </div>

          {/* Second Row */}
          <div className="w3-col" style={{ marginTop: "50px" }}>
            <div
              className="w3-col l6 m6 s12 w3-center"
              style={{ padding: "5px" }}
            >
              <br />
              <Slide up>
                <img
                  className="w3-margin-top w3-round-xlarge w3-margin-bottom"
                  src={`${
                    process.env.PUBLIC_URL +
                    "/images/aboutPage/about-us-03-image-02.png"
                  }`}
                  alt="image_"
                  style={{
                    maxWidth: "80%",
                  }}
                />
              </Slide>
            </div>

            <div className="w3-col l6 m6 s12" style={{ padding: "5px" }}>
              <Slide up>
                <div
                  className="w3-col w3-card-2 w3-round-large sitePrimaryBg w3-padding"
                  style={{ marginBottom: "20px" }}
                >
                  <p className="fontJostRegular w3-medium">
                    At LampHills, we understand that reputation is not just a
                    name, it reflects who you are and what you stand for. So,
                    our experienced team is dedicated to working with you to
                    enhance your public image, manage a crisis, or make a
                    memorable impact. We help you shape the conversations around
                    you and tell your story in a way that resonates with your
                    audience.
                  </p>
                </div>
              </Slide>
              <Slide up>
                <div
                  className="w3-col w3-card-2 w3-round-large siteSecondaryyBg  w3-padding"
                  style={{ marginBottom: "20px" }}
                >
                  <p className="fontJostRegular w3-medium">
                    We reshape reputations and build lasting legacies. And with
                    a blend of strategic expertise and creativity, we create
                    compelling narratives that elevate your brand and leave a
                    lasting impact. We pride ourselves on our unwavering
                    commitment to delivering exceptional results while ensuring
                    a collaborative environment that allows ideas to flourish
                    and your reputation and legacy to thrive.
                  </p>
                </div>
              </Slide>
              <Slide up>
                <div
                  className="w3-col w3-card-2 w3-round-large sitePrimaryBg w3-padding"
                  style={{ marginBottom: "20px" }}
                >
                  <p className="fontJostRegular w3-medium">
                    More importantly, LampHills places you at the heart of
                    everything we do. Our commitment to clear communication,
                    transparent processes, and a client-centric approach ensures
                    that your success remains our top priority. Count on us to
                    be by your side every step of the way, guiding you towards
                    building the finest perceptions.
                  </p>
                </div>
              </Slide>
            </div>
          </div>
        </div>
      </div>

      {/* Content  */}

      <OurTeamComp></OurTeamComp>

      {/* Our Service */}
      <ServiceSlide></ServiceSlide>
      {/* Our Service */}

      {/* Testimony */}
      <TestimonyComp></TestimonyComp>
      {/* Testimony */}
    </div>
  );
};

export default AboutPage;
