import { LoadingOutlined } from "@ant-design/icons";
import { useState } from "react";
import { sampleApiCall } from "../../../apiservice/sampleUsage/sample";
import useFormatApiRequest from "../../../hooks/formatApiRequest";
import "../sharedLandingcss/Site.css";
import "../sharedLandingcss/Site_Animate.css";
import Slide from "react-reveal/Slide";
import emailjs from "@emailjs/browser";

export const ContactComp = () => {
  const [loadApi, setLoadApi] = useState(false);
  const [payLoad, setpayLoad] = useState<any>({});
  const [formLoading, setFormLoading] = useState<boolean>(false);

  // Use to collect Input change Change
  const handleInputChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    setpayLoad((values: any) => ({ ...values, [name]: value }));
  };

  // Use to Submit Form
  const handleSubmit = (event: any) => {
    event.preventDefault();
    setFormLoading(true);

    emailjs
      .send(
        "service_Lamphill",
        "template_Owner",
        {
          message: payLoad?.message,
          fullName: payLoad?.fullName,
          email: payLoad?.email,
          subject: payLoad?.subject,
        },
        {
          publicKey: "bPhK6vjI5kNa_T6Ot",
        }
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          alert("Your Form Have been sent");
          setFormLoading(false);
          sendReplyMessage();
        },
        (error) => {
          console.log("FAILED...", error);
          alert(error);

          setFormLoading(false);
        }
      );
  };

  const sendReplyMessage = () => {
    emailjs
      .send(
        "service_Lamphill",
        "template_sender",
        {
          email: payLoad?.email,
        },
        {
          publicKey: "bPhK6vjI5kNa_T6Ot",
        }
      )
      .then(
        (response) => {
          console.log("SUCCESS! Reply Mail", response.status, response.text);
          setFormLoading(false);
        },
        (error) => {
          console.log("FAILED... Reply Mail", error);
          setFormLoading(false);
        }
      );
  };

  // A custom hook to save form data
  const result = useFormatApiRequest(
    () => sampleApiCall(payLoad),
    loadApi,
    () => {
      setLoadApi(false);
    },
    () => {
      processFormApi();
    }
  );

  // Process Api
  const processFormApi = async () => {
    if (result.httpState === "SUCCESS") {
      setFormLoading(false);

      alert("Your Form Have been sent");
      // Handle Success Here
    } else if (result.httpState === "ERROR") {
      setFormLoading(false);
      alert(result.data?.response?.data?.message || result.errorMsg);
      //Handle Error Here
    }
  };

  return (
    <>
      {/* Contact Us */}
      <div className="w3-pink contactBg  w3-col ">
        <div className="w3-content">
          <Slide up>
            <div className="w3-col">
              <div className="w3-col l4 s12 m12" style={{ padding: "5px" }}>
                <div className="w3-col">
                  <p className="fontJostMedium w3-large">
                    Still Not Convience?
                  </p>
                  <div>
                    <h1 className="fontJostBold w3-xxxlarge">
                      Contact Us <br />
                      For More Info
                    </h1>
                  </div>
                </div>
              </div>

              <div className="w3-col l8 s12 m12" style={{ padding: "5px" }}>
                <form onSubmit={handleSubmit}>
                  <div className="w3-col ">
                    {/* Full Name */}
                    <div
                      className="w3-col l6 s12 m6 w3-margin-bottom"
                      style={{ paddingRight: "4px" }}
                    >
                      <input
                        required
                        name="fullName"
                        value={payLoad?.fullName || ""}
                        onChange={handleInputChange}
                        className="w3-input w3-border w3-col w3-text-white  w3-border-white w3-round-xlarge regFormInput"
                        placeholder="Full Name"
                      />
                    </div>
                    {/* Email */}
                    <div
                      className="w3-col l6 s12 m6 w3-margin-bottom"
                      style={{ paddingLeft: "4px" }}
                    >
                      <input
                        required
                        name="email"
                        value={payLoad?.email || ""}
                        onChange={handleInputChange}
                        className="w3-input w3-border w3-col w3-text-white w3-round-xlarge regFormInput"
                        placeholder="Email"
                        type="email"
                      />
                    </div>
                  </div>

                  {/* Subject */}
                  <div className="w3-col w3-margin-bottom">
                    <div className="w3-col l12 s12 m12">
                      <input
                        required
                        name="subject"
                        value={payLoad?.subject || ""}
                        onChange={handleInputChange}
                        className="w3-input w3-border w3-col w3-text-white w3-round-xlarge regFormInput "
                        placeholder="Subject"
                      />
                    </div>
                  </div>

                  {/* Message */}
                  <div className="w3-col w3-margin-bottom">
                    <div className="w3-col l12 s12 m12">
                      <textarea
                        style={{ height: "200px" }}
                        required
                        name="message"
                        value={payLoad?.message || ""}
                        onChange={handleInputChange}
                        className="w3-input w3-border w3-col w3-text-white w3-round-xlarge regFormInput "
                        placeholder="Message"
                      ></textarea>
                    </div>
                  </div>

                  <div className="w3-padding regButtonHolder">
                    <button
                      className="w3-btn getAnalysisBtn w3-round-xxlarge"
                      disabled={formLoading}
                    >
                      {!formLoading ? (
                        "Send Message"
                      ) : (
                        <LoadingOutlined rev={undefined} />
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Slide>
        </div>
      </div>
      {/* Contact Us */}
    </>
  );
};

export default ContactComp;
