import { DownOutlined, MenuOutlined } from "@ant-design/icons";
import { Drawer } from "antd";
import { useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";

const Navbar = () => {
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="w3-col navbarcontainerwrapper w3-card-2 w3-border-bottom">
        <div className="w3-content">
          <div className="w3-col l3 m6 s6">
            <div>
              <Link className="link" to="/">
                <span>
                  <img
                    style={{ width: "50%" }}
                    className="favicon-header"
                    src={`${process.env.PUBLIC_URL + "/images/logo.png"}`}
                    alt=""
                  />
                </span>
              </Link>
            </div>
          </div>

          <div className="w3-col l3 m6 s6 w3-right-align w3-hide-large">
            <MenuOutlined onClick={showDrawer} rev={undefined} />
          </div>

          <div className="w3-col l6 m6 s12 w3-center w3-hide-medium w3-hide-small">
            <div className="linkwrapper">
              <Link className="link w3-padding" to="/">
                Home
              </Link>
              <Link className="link w3-padding" to="/landing/about">
                About
              </Link>
              <span
                className="w3-dropdown-hover link"
                style={{ width: "80px" }}
              >
                <button className="w3-button">
                  Services{" "}
                  <span style={{ zoom: "0.7" }}>
                    <DownOutlined />
                  </span>
                </button>
                <span className="w3-dropdown-content w3-bar-block w3-round-large">
                  <Link
                    className="w3-bar-item w3-button"
                    to="/landing/services/1/Crisis_Management"
                  >
                    Crisis Management
                  </Link>
                  <Link
                    className="w3-bar-item w3-button"
                    to="/landing/services/2/Reputation_Brand_Boosting"
                  >
                    Reputation & Brand Boosting
                  </Link>
                  <Link
                    className="w3-bar-item w3-button"
                    to="/landing/services/3/Launch%20PR-Event%20Management"
                  >
                    Launch PR/Event Management
                  </Link>

                  <Link
                    className="w3-bar-item w3-button"
                    to="/landing/services/4/Reputation_Brand_Boosting"
                  >
                    Media Relations
                  </Link>
                  <Link
                    className="w3-bar-item w3-button"
                    to="/landing/services/5/Content_Creation_Package"
                  >
                    Content Creation Package
                  </Link>
                  <Link
                    className="w3-bar-item w3-button"
                    to="/landing/services/6/Press_Releases_and_Speeches"
                  >
                    Press Releases and Speeches
                  </Link>
                </span>
              </span>
              <a
                className="link  w3-padding"
                href="https://blog.lamphills.com/"
                target="_blank"
                rel="noreferrer"
              >
                Blog
              </a>
              <Link className="link w3-padding" to="/landing/contactus">
                Contact Us
              </Link>
            </div>
          </div>

          <div className="w3-col l3 m6 s6 w3-center w3-hide-small w3-hide-medium">
            <Link
              className=" w3-btn w3-round-xxlarge w3-text-white callBtn"
              to="/"
            >
              <span> +234 704 390 7787</span>
            </Link>
          </div>
        </div>
      </div>

      <div className="w3-col navBarSpace">
        <br />
      </div>

      <Drawer
        title="Lamphills"
        placement="right"
        onClose={onClose}
        open={open}
        width={300}
      >
        <p onClick={() => setOpen(!open)}>
          <Link className="w3-padding myfont1" to="/">
            Home
          </Link>
        </p>
        <p onClick={() => setOpen(!open)}>
          <Link className="w3-padding myfont1" to="/landing/about">
            About
          </Link>
        </p>
        <p>
          <span
            className="w3-dropdown-hover myfont1 link w3-white"
            style={{ width: "100%" }}
          >
            <button className="w3-button">
              Services{" "}
              <span style={{ zoom: "0.7" }}>
                <DownOutlined />
              </span>
            </button>
            <span className="w3-dropdown-content w3-bar-block w3-white w3-round-large">
              <Link
                onClick={() => setOpen(!open)}
                className="w3-bar-item w3-button"
                to="/landing/services/1/Crisis_Management"
              >
                Crisis Management
              </Link>
              <Link
                onClick={() => setOpen(!open)}
                className="w3-bar-item w3-button"
                to="/landing/services/2/Reputation_Brand_Boosting"
              >
                Reputation & Brand Boosting
              </Link>
              <Link
                onClick={() => setOpen(!open)}
                className="w3-bar-item w3-button"
                to="/landing/services/3/Launch%20PR-Event%20Management"
              >
                Launch PR/Event Management
              </Link>

              <Link
                onClick={() => setOpen(!open)}
                className="w3-bar-item w3-button"
                to="/landing/services/4/Reputation_Brand_Boosting"
              >
                Media Relations
              </Link>
              <Link
                onClick={() => setOpen(!open)}
                className="w3-bar-item w3-button"
                to="/landing/services/5/Content_Creation_Package"
              >
                Content Creation Package
              </Link>
              <Link
                onClick={() => setOpen(!open)}
                className="w3-bar-item w3-button"
                to="/landing/services/6/Press_Releases_and_Speeches"
              >
                Press Releases and Speeches
              </Link>
            </span>
          </span>
        </p>
        <p onClick={() => setOpen(!open)}>
          <a
            className="w3-padding myfont1"
            href="https://blog.lamphills.com/"
            target="_blank"
            rel="noreferrer"
          >
            Blog
          </a>
        </p>
        <p onClick={() => setOpen(!open)}>
          <Link className="w3-padding myfont1" to="/landing/contactus">
            Contact
          </Link>
        </p>

        <div>
          <p onClick={() => setOpen(!open)}>
            <Link
              className="callBtn w3-center w3-col w3-round-xlarge w3-text-white"
              to="/"
            >
              <span>+234 704 390 7787</span>
            </Link>
          </p>
        </div>
      </Drawer>
    </>
  );
};

export default Navbar;
