import "../sharedLandingcss/Site.css";
import "../sharedLandingcss/Site_Animate.css";

export const MotionCompHeroTop = () => {
  return (
    <>
      <div style={{ height: "40px", position: "relative" }}>
        <span className="moveHero_1">
          <img
            style={{ maxWidth: "80%", width: "30px" }}
            alt="heroImage"
            src={`${process.env.PUBLIC_URL + "/images/homePage/img-2-1.png"}`}
          />{" "}
          &nbsp;
        </span>

        <span className="moveHero_2">
          <img
            style={{ maxWidth: "80%", width: "30px" }}
            alt="heroImage"
            src={`${process.env.PUBLIC_URL + "/images/homePage/img-3.png"}`}
          />{" "}
          &nbsp;
        </span>

        <span className="moveHero_3">
          <img
            style={{ maxWidth: "80%", width: "100px" }}
            alt="heroImage"
            src={`${process.env.PUBLIC_URL + "/images/homePage/img-17.png"}`}
          />{" "}
          &nbsp;
        </span>

        <span className="moveHero_4">
          <img
            style={{ maxWidth: "80%", width: "100px" }}
            alt="heroImage"
            src={`${process.env.PUBLIC_URL + "/images/homePage/img-18.png"}`}
          />{" "}
          &nbsp;
        </span>

        <span className="moveHero_5">
          <img
            style={{ maxWidth: "80%", width: "100px" }}
            alt="heroImage"
            src={`${process.env.PUBLIC_URL + "/images/homePage/img-16.png"}`}
          />{" "}
          &nbsp;
        </span>

        <span className="moveHero_6">
          <img
            style={{ maxWidth: "80%", width: "100px" }}
            alt="heroImage"
            src={`${process.env.PUBLIC_URL + "/images/homePage/img-14.png"}`}
          />{" "}
          &nbsp;
        </span>

        <br />
      </div>
    </>
  );
};

export default MotionCompHeroTop;
