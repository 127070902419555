import { IList } from "../../../utils/generalType";
import "../sharedLandingcss/Site.css";
import "../sharedLandingcss/Site_Animate.css";
import Slide from "react-reveal/Slide";

export const OurTeamComp = () => {
  const team: IList[] = [
    {
      title: " Chibby Precious",
      subTitle: "Content Creator",
      image: `${process.env.PUBLIC_URL + "/images/aboutPage/team1.jpeg"}`,
    },
    {
      title: " Peace Nwadike Umeh",
      subTitle: "Communications Specialist",
      image: `${process.env.PUBLIC_URL + "/images/aboutPage/team2.jpeg"}`,
    },
    // {
    //   title: " Chibby Precious",
    //   subTitle: "Content Creator",
    //   image: `${process.env.PUBLIC_URL + "/images/aboutPage/team3.jpeg"}`,
    // },
    {
      title: " Ifeanyi Ezirim ",
      subTitle: "Media Relations Coordinator",
      image: `${process.env.PUBLIC_URL + "/images/aboutPage/team4.jpeg"}`,
    },
    {
      title: " Stanley Nlagu",
      subTitle: "PR/HR Manager",
      image: `${process.env.PUBLIC_URL + "/images/aboutPage/team5.jpeg"}`,
    },
    {
      title: "Ruth Otori",
      subTitle: "Social Media Manager",
      image: `${process.env.PUBLIC_URL + "/images/aboutPage/team6.jpeg"}`,
    },
  ];
  return (
    <>
      {/* Our Team */}
      <div className="w3-col teamContainer">
        <div className="w3-content w3-padding">
          <div className=" w3-center">
            <h2 className="fontJostBold w3-xxlarge">Our Team</h2>
            <p className="myfont1">
              {" "}
              Meet the Team Behind Your Next Success Story
            </p>
          </div>

          {team.map((item: IList, index: number) => (
            <Slide up>
              <div
                className="w3-col w3-col l4 m6 s12"
                style={{ padding: "15px" }}
              >
                <div className="w3-col w3-padding w3-round-xlarge w3-card siteSecondaryyBg w3-center">
                  <div
                    className="w3-col"
                    style={{
                      marginTop: "20px",
                      marginBottom: "20px",
                    }}
                  >
                    <img
                      className="w3-margin-top w3-margin-bottom w3-circle"
                      src={item.image}
                      alt="image_"
                      style={{
                        maxWidth: "75%",
                        height: "150px",
                      }}
                    />
                  </div>

                  <h4 className="fontJostBold w3-large">{item.title}</h4>
                  <p className="fontJostRegular w3-medium">{item.subTitle}</p>
                </div>
              </div>
              {/* <div className="w3-col teamContainer">
                <div
                  className={
                    "w3-col l4 s12 m4 w3-center " +
                    (index % 2 === 0 ? " w3-left" : " w3-right")
                  }
                  style={{ paddingTop: "50px" }}
                >
                  <h1 className="fontJostBold w3-xxxlarge heroHeaderTextPurple">
                    {item.title}
                  </h1>
                </div>
                <div
                  className={
                    "w3-left w3-col l4 s12 m4 w3-center " +
                    (index % 2 === 0 ? " w3-left" : " w3-right")
                  }
                >
                  <img
                    src={item.image}
                    className="w3-circle"
                    alt="image_"
                    style={{ width: "200px", height: "200px" }}
                  />
                </div>
                <div
                  className={
                    "w3-right w3-col l4 s12 m4 w3-center" +
                    (index % 2 === 0 ? " w3-left" : " w3-right")
                  }
                  style={{ paddingTop: "50px" }}
                >
                  <h1> {item.subTitle}</h1>
                </div>
              </div> */}
            </Slide>
          ))}
        </div>
      </div>
      {/* Our Team */}
    </>
  );
};

export default OurTeamComp;
