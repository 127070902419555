import MotionCompGeneral from "../sharedLandingcomponents/Motion_Comp";
import PagerHeaderComp from "../sharedLandingcomponents/PagesHeaderComp";
import "./TermsAndCondtions.css";
const TermsAndCondtions = () => {
  return (
    <div className="w3-white w3-col" style={{ minHeight: "400px" }}>
      <PagerHeaderComp
        title="LAMPHILLS TERMS OF SERVICE"
        subTitle="AGREEMENT TO OUR LEGAL TERMS"
      ></PagerHeaderComp>

      {/* Content  */}
      <div className="w3-white otherContentBg w3-col">
        {/* Motions for Traffic */}
        <div className="w3-hide-small w3-hide-medium">
          <MotionCompGeneral
            showMoveLeft={true}
            showMoveRight={true}
            showRotateRight={true}
            rotateRightImg={`${
              process.env.PUBLIC_URL + "/images/homePage/spec-el-2.png"
            }`}
          ></MotionCompGeneral>
        </div>
        <div className="w3-content w3-padding">
          <div className="fontJostRegular w3-large">
            <>
              <p>
                <span style={{ fontWeight: 400 }}>
                  We are LampHills ('Company,' 'we,' 'us,' or 'our'), registered
                  in Abuja, Nigeria. We operate the website
                  http://www.lamphills.com (the 'Site') and any other related
                  products and services that refer to or link to these legal
                  terms (the 'Legal Terms') (collectively, the
                  'Services').&nbsp;
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  These Terms constitute a legally binding agreement between
                  you, whether personally or on behalf of an entity ('you'), and
                  LampHills concerning your access to and use of the Services.
                  You agree that by accessing the Services, you have read,
                  understood, and agreed to be bound by these Terms. IF YOU DO
                  NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE
                  EXPRESSLY PROHIBITED FROM USING THE SERVICES, AND YOU MUST
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  DISCONTINUE USE IMMEDIATELY.
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  Supplemental terms and conditions or documents that may be
                  posted on the Services from time to time are hereby expressly
                  incorporated herein by reference. In our sole discretion, we
                  reserve the right to change or modify these Legal Terms at any
                  time and for any reason. We will alert you about any changes
                  by updating the 'Last updated' date of these Legal Terms, and
                  you waive any right to receive specific notice of each such
                  change. You must review these Legal Terms to stay informed of
                  updates periodically. You will be subject to and will be
                  deemed to have been made aware of and accepted the changes in
                  any revised Legal Terms by your continued use of the Services
                  after the date such revised Legal Terms are posted.
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  The Services are intended for users who are at least 18 years
                  old. Persons under 18 are not permitted to use or register for
                  the Services. We recommend that you print a copy of these
                  Legal Terms for your records
                </span>
              </p>
              <p>&nbsp;</p>
              <ol>
                <li>
                  <strong> OUR SERVICES</strong>
                </li>
              </ol>
              <p>
                <span style={{ fontWeight: 400 }}>
                  The information provided when using the Services is not
                  intended for distribution or use by any person or entity in
                  any jurisdiction or country where such distribution or use
                  would be contrary to law or regulation or which would subject
                  us to any registration requirement within such jurisdiction or
                  country. Accordingly, those persons who choose to access the
                  Services from other locations do so on their initiative and
                  are solely responsible for compliance with local laws if and
                  to the extent local laws are applicable.
                </span>
              </p>
              <ol start={2}>
                <li>
                  <strong> INTELLECTUAL PROPERTY RIGHTS</strong>
                </li>
              </ol>
              <p>&nbsp;</p>
              <p>
                <strong>Our intellectual property</strong>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  We are the owner or the licensee of all intellectual property
                  rights in our Services, including all source code, databases,
                  functionality, software, website designs, audio, video, text,
                  photographs, and graphics in the Services (collectively, the
                  'Content'), as well as the trademarks, service marks, and
                  logos contained therein (the 'Marks').&nbsp;
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  Our Content and Marks are protected by copyright and trademark
                  laws (and various other intellectual property rights and
                  unfair competition laws) and treaties in the United States and
                  worldwide.
                </span>
              </p>
              <p>&nbsp;</p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  The Content and Marks are provided in or through the Services
                  only for personal, non-commercial, or internal business use.
                </span>
              </p>
              <p>&nbsp;</p>
              <p>
                <strong>Your use of our Services</strong>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  Subject to your compliance with these Legal Terms, including
                  the 'PROHIBITED ACTIVITIES' section below, we grant you a
                  non-exclusive, non-transferable, revocable license to:&nbsp;
                </span>
              </p>
              <ul>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <span style={{ fontWeight: 400 }}>
                    access the Services and&nbsp;
                  </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <span style={{ fontWeight: 400 }}>
                    download or print a copy of any portion of the Content to
                    which you have properly gained access
                  </span>
                </li>
              </ul>
              <p>&nbsp;</p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  solely for your personal, non-commercial use or internal
                  business purpose.&nbsp;
                </span>
              </p>
              <p>&nbsp;</p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  Except as set out in this section or elsewhere ie, in our
                  Legal Terms, no part of the Services and no Content or Marks
                  may be copied, reproduced, aggregated, republished, uploaded,
                  posted, publicly displayed, encoded, translated, transmitted,
                  distributed, sold, licensed, or otherwise exploited for any
                  commercial purpose whatsoever, without our express prior
                  written permission.
                </span>
              </p>
              <p>&nbsp;</p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  If you wish to use the Services, Content, or Marks other than
                  as set out in this section or elsewhere in our Legal Terms,
                  please address your request to our contact email.
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>&nbsp;</span>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  Suppose we grant you permission to post, reproduce, or
                  publicly display any part of our Services or Content. In that
                  case, you must identify us as the owners or licensors of the
                  Services, Content, or Marks and ensure that any copyright or
                  proprietary notice appears or is visible on posting,
                  reproducing, or displaying our Content.
                </span>
              </p>
              <p>&nbsp;</p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  We reserve all rights not expressly granted to you in and to
                  the Services, Content, and Marks.
                </span>
              </p>
              <p>&nbsp;</p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  Any breach of these Intellectual Property Rights will
                  constitute a material breach of our Legal Terms, and your
                  right to use our Services will terminate immediately.
                </span>
              </p>
              <p>&nbsp;</p>
              <p>
                <strong>Your submissions</strong>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  Please review this section and the 'PROHIBITED ACTIVITIES'
                  section carefully before using our Services to understand the
                  (a) rights you give us and (b) obligations you have when you
                  post or upload any content through the Services.
                </span>
              </p>
              <p>&nbsp;</p>
              <p>
                <strong>Submissions</strong>
                <span style={{ fontWeight: 400 }}>
                  : By directly sending us any question, comment, suggestion,
                  idea, feedback, or other information about the Services
                  ('Submissions'), you agree to assign to us all intellectual
                  property rights in such Submission. You agree that we shall
                  own this Submission and be entitled to its unrestricted use
                  and dissemination for any lawful purpose, commercial or
                  otherwise, without acknowledgment or compensation to you.
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  You are responsible for what you post or upload. By sending us
                  Submissions through any part of the Services, you:
                </span>
              </p>
              <ul>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <span style={{ fontWeight: 400 }}>
                    confirm that you have read and agree with our 'PROHIBITED
                    ACTIVITIES' and will not post, send, publish, upload, or
                    transmit through the Services any Submission that is
                    illegal, harassing, hateful, harmful, defamatory, obscene,
                    bullying, abusive, discriminatory, threatening to any person
                    or group, sexually explicit, false, inaccurate, deceitful,
                    or misleading;
                  </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <span style={{ fontWeight: 400 }}>
                    to the extent permissible by applicable law, waive any moral
                    rights to any such Submission;&nbsp;
                  </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <span style={{ fontWeight: 400 }}>
                    warrant that any such Submission is original to you or that
                    you have the necessary rights and licenses to submit such
                    Submissions and that you have full authority to grant us the
                    rights mentioned above about your Submissions; and
                  </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <span style={{ fontWeight: 400 }}>
                    warrant and represent that your Submissions do not
                    constitute confidential information.
                  </span>
                </li>
              </ul>
              <p>
                <span style={{ fontWeight: 400 }}>
                  You are solely responsible for your Submissions, and you
                  expressly agree to reimburse us for any losses that we may
                  suffer because you breach (a) this section, (b) any third
                  party’s intellectual property rights, or (c) applicable law.
                </span>
              </p>
              <ol start={3}>
                <li>
                  <strong> USER REPRESENTATIONS</strong>
                </li>
              </ol>
              <p>
                <span style={{ fontWeight: 400 }}>
                  By using the Services, you represent and warrant that: (1) you
                  have the legal capacity, and you agree to comply with these
                  Legal Terms; (2) you are not a minor in the jurisdiction in
                  which you reside; (3) you will not access the Services through
                  automated or non-human means, whether through a bot, script,
                  or otherwise; (4) you will not use the Services for any
                  illegal or unauthorized purpose; and (5) your use of the
                  Services will not violate any applicable law or regulation.
                </span>
              </p>
              <p>&nbsp;</p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  Suppose you provide any information that is untrue,
                  inaccurate, not current, or incomplete. In that case, we have
                  the right to suspend or terminate your account and refuse any
                  current or future use of the Services (or any portion
                  thereof).
                </span>
              </p>
              <p>&nbsp;</p>
              <ol start={4}>
                <li>
                  <strong> PRODUCTS</strong>
                </li>
              </ol>
              <p>
                <span style={{ fontWeight: 400 }}>
                  All products are subject to availability. We reserve the right
                  to discontinue any product anytime for any reason. Prices for
                  all products are subject to change.
                </span>
              </p>
              <p>&nbsp;</p>
              <ol start={5}>
                <li>
                  <strong> PURCHASES AND PAYMENT</strong>
                </li>
              </ol>
              <p>
                <span style={{ fontWeight: 400 }}>
                  We accept the following forms of payment:
                </span>
              </p>
              <ul>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <span style={{ fontWeight: 400 }}>Visa</span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <span style={{ fontWeight: 400 }}>PayPal</span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <span style={{ fontWeight: 400 }}>Mastercard</span>
                </li>
              </ul>
              <p>
                <span style={{ fontWeight: 400 }}>
                  You agree to provide current, complete, and accurate purchase
                  and account information for all purchases made via the
                  Services. You further agree to promptly update account and
                  payment information, including email address, payment method,
                  and payment card expiration date, so we can complete your
                  transactions and contact you as needed. Sales tax will be
                  added to the purchase price as we deem required. We may change
                  prices at any time. All payments shall be in Naira.
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  You agree to pay all charges at the prices then in effect for
                  your purchases and any applicable shipping fees, and you
                  authorise us to charge your chosen payment provider for any
                  such amounts upon placing your order. We reserve the right to
                  correct any errors or mistakes in pricing, even if we have
                  already requested or received payment.
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  We reserve the right to refuse any order placed through the
                  Services. In our sole discretion, we may limit or cancel
                  quantities purchased per person, household, or order. These
                  restrictions may include orders placed by or under the same
                  customer account, payment method, and/or orders using the same
                  billing or shipping address. We reserve the right to limit or
                  prohibit orders that, in our sole judgment, appear to be
                  placed by dealers, resellers, or distributors.
                </span>
              </p>
              <ol start={6}>
                <li>
                  <strong> REFUNDS POLICY</strong>
                </li>
              </ol>
              <p>
                <span style={{ fontWeight: 400 }}>
                  All sales are final, and no refund will be issued.
                </span>
              </p>
              <ol start={7}>
                <li>
                  <strong> SOFTWARE</strong>
                </li>
              </ol>
              <p>
                <span style={{ fontWeight: 400 }}>
                  We may include software for use in connection with our
                  Services. If such software is accompanied by an end-user
                  license agreement ('EULA'), the terms of the EULA will govern
                  your use of the software. Suppose an EULA does not accompany
                  such software. In that case, we grant you a non-exclusive,
                  revocable, personal, and non-transferable license to use such
                  software solely in connection with our services and by these
                  Legal Terms. Any software and related documentation is
                  provided 'ASIS' without warranty, either express or implied,
                  including, without limitation, the implied warranties of
                  merchantability, fitness for a particular purpose, or
                  non-infringement. You accept any risks arising from the use or
                  performance of any software. You may not reproduce or
                  redistribute any software except by the EULA or these Legal
                  Terms.
                </span>
              </p>
              <p>&nbsp;</p>
              <ol start={8}>
                <li>
                  <strong> PROHIBITED ACTIVITIES</strong>
                </li>
              </ol>
              <p>
                <span style={{ fontWeight: 400 }}>
                  You may not access or use the Services for any purpose other
                  than that for which we make the Services available. The
                  Services may not be used in connection with any commercial
                  endeavors except those specifically endorsed or approved by
                  us.
                </span>
              </p>
              <p>&nbsp;</p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  As a user of the Services, you agree not to:
                </span>
              </p>
              <p>&nbsp;</p>
              <ul>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <span style={{ fontWeight: 400 }}>
                    Systematically retrieve data or other content from the
                    Services to create or compile, directly or indirectly, a
                    collection, compilation, database, or directory without our
                    written permission.
                  </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <span style={{ fontWeight: 400 }}>
                    Trick, defraud, or mislead us and other users, especially in
                    any attempt to learn sensitive account information, such as
                    user passwords.
                  </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <span style={{ fontWeight: 400 }}>
                    Circumvent, disable, or otherwise interfere with
                    security-related features of the Services, including
                    features that prevent or restrict the use or copying of any
                    Content or enforce limitations on the use of the Services
                    and/or the Content contained therein.
                  </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <span style={{ fontWeight: 400 }}>
                    Disparage, tarnish, or otherwise harm, in our opinion, us
                    and/or the Services.
                  </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <span style={{ fontWeight: 400 }}>
                    Use any information obtained from the Services to harass,
                    abuse, or harm another person.
                  </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <span style={{ fontWeight: 400 }}>
                    Use our support services improperly or submit false reports
                    of abuse or misconduct.
                  </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <span style={{ fontWeight: 400 }}>
                    Use the Services in a manner inconsistent with any
                    applicable laws or regulations.
                  </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <span style={{ fontWeight: 400 }}>
                    Engage in unauthorized framing of or linking to the
                    Services.
                  </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <span style={{ fontWeight: 400 }}>
                    Upload or transmit (or attempt to upload or to transmit)
                    viruses, Trojan horses, or other material, including
                    excessive use of capital letters and spamming (continuous
                    posting of repetitive text), that interferes with any
                    party’s uninterrupted use and enjoyment of the Services or
                    modifies, impairs, disrupts, alters, or interferes with the
                    use, features, functions, operation, or maintenance of the
                    Services.
                  </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <span style={{ fontWeight: 400 }}>
                    Engage in any automated system use, such as scripts to send
                    comments or messages, data mining, robots, or similar data
                    gathering and extraction tools.
                  </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <span style={{ fontWeight: 400 }}>
                    Delete the copyright or other proprietary rights notice from
                    any Content.
                  </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <span style={{ fontWeight: 400 }}>
                    Attempt to impersonate another user or person or use the
                    username of another user.
                  </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <span style={{ fontWeight: 400 }}>
                    Upload or transmit (or attempt to upload or to transmit) any
                    material that acts as a passive or active information
                    collection or transmission mechanism, including, without
                    limitation, clear graphics interchange formats ('gifs'), 1×1
                    pixels, web bugs, cookies, or other similar devices
                    (sometimes referred to as spyware or 'passive collection
                    mechanisms' or 'pcms').
                  </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <span style={{ fontWeight: 400 }}>
                    Interfere with, disrupt, or create an undue burden on the
                    Services or the networks or services connected to the
                    Services.
                  </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <span style={{ fontWeight: 400 }}>
                    Harass, annoy, intimidate, or threaten any of our employees
                    or agents who provide you with any portion of the Services.
                  </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <span style={{ fontWeight: 400 }}>
                    Attempt to bypass any measures of the Services designed to
                    prevent or restrict access to the Services or any portion of
                    the Services.
                  </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <span style={{ fontWeight: 400 }}>
                    Copy or adapt the Services' software, including but not
                    limited to Flash, PHP, HTML, JavaScript, or other code.
                  </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <span style={{ fontWeight: 400 }}>
                    Except as permitted by applicable law, decipher, decompile,
                    disassemble, or reverse engineer any software comprising or
                    making up a part of the Services.
                  </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <span style={{ fontWeight: 400 }}>
                    Except as may be the result of the standard search engine or
                    Internet browser usage, use, launch, develop, or distribute
                    any automated system, including, without limitation, any
                    spider, robot, cheat utility, scraper, or offline reader
                    that accesses the Services, or use or launch any
                    unauthorized script or other software.
                  </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <span style={{ fontWeight: 400 }}>
                    Use a buying agent or purchasing agent to make purchases on
                    the Services.
                  </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <span style={{ fontWeight: 400 }}>
                    Make any unauthorized use of the Services, including
                    collecting users' usernames and/or email addresses by
                    electronic or other means to send unsolicited emails or
                    create user accounts by automated means or under pretenses.
                  </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <span style={{ fontWeight: 400 }}>
                    Use the Services as part of any effort to compete with us or
                    otherwise use the
                  </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <span style={{ fontWeight: 400 }}>
                    Services and/or the Content for any revenue-generating
                    endeavor or commercial enterprise.
                  </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <span style={{ fontWeight: 400 }}>
                    Use the Services to advertise or offer to sell goods and
                    services
                  </span>
                </li>
              </ul>
              <ol start={9}>
                <li>
                  <strong> USER GENERATED CONTRIBUTIONS</strong>
                </li>
              </ol>
              <p>
                <span style={{ fontWeight: 400 }}>
                  The Services does not offer users to submit or post content.
                  We may provide you with the opportunity to create, submit,
                  post, display, transmit, perform, publish, distribute, or
                  broadcast content and materials to us or on the Services,
                  including but not limited to text, writings, video, audio,
                  photographs, graphics, comments, suggestions, or personal
                  information or other material (collectively, 'Contributions').
                  Contributions may be viewable by other users of the Services
                  and through third-party websites.&nbsp;
                </span>
              </p>
              <p>&nbsp;</p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  When you create or make available any Contributions, you
                  thereby represent and warrant that:
                </span>
              </p>
              <ul>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <span style={{ fontWeight: 400 }}>
                    The creation, distribution, transmission, public display, or
                    performance, and the accessing, downloading, or copying of
                    your Contributions do not and will not infringe the
                    proprietary rights of any third party, including but not
                    limited to the copyright, patent, trademark, trade secret,
                    or moral rights of any third party.
                  </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <span style={{ fontWeight: 400 }}>
                    You are the creator and owner of or have the necessary
                    licenses, rights, consents, releases, and permissions to use
                    and to authorize us, the Services, and other users of the
                    Services to use your Contributions in any manner
                    contemplated by the Services and these Legal Terms.
                  </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <span style={{ fontWeight: 400 }}>
                    You have the written consent, release, and/or permission of
                    each and every identifiable person in your Contributions to
                    use the name or likeness of every such identifiable person
                    to enable inclusion and use of your Contributions in any
                    manner contemplated by the Services and these Legal Terms.
                  </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <span style={{ fontWeight: 400 }}>
                    Your Contributions are not false, inaccurate, or misleading.
                  </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <span style={{ fontWeight: 400 }}>
                    Your Contributions are not unsolicited or unauthorized
                    advertising, promotional materials, pyramid schemes, chain
                    letters, spam, mass mailings, or other forms of
                    solicitation.
                  </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <span style={{ fontWeight: 400 }}>
                    Your Contributions are not obscene, lewd, lascivious,
                    filthy, violent, harassing, libelous, slanderous, or
                    otherwise objectionable (as determined by us).
                  </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <span style={{ fontWeight: 400 }}>
                    Your Contributions do not ridicule, mock, disparage,
                    intimidate, or abuse anyone.
                  </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <span style={{ fontWeight: 400 }}>
                    Your Contributions are not used to harass or threaten (in
                    the legal sense of those terms) any other person or to
                    promote violence against a specific person or class of
                    people.
                  </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <span style={{ fontWeight: 400 }}>
                    Your Contributions do not violate any applicable law
                    concerning child pornography or otherwise intended to
                    protect the health or well-being of minors.
                  </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <span style={{ fontWeight: 400 }}>
                    Your Contributions do not include offensive comments
                    connected to race, national origin, gender, sexual
                    preference, or physical handicap.
                  </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <span style={{ fontWeight: 400 }}>
                    Your Contributions do not otherwise violate or link to
                    material that violates any provision of these Legal Terms or
                    any applicable law or regulation.
                  </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <span style={{ fontWeight: 400 }}>
                    Any use of the Services in violation of the preceding
                    violates these Legal Terms and may result in, among other
                    things, termination or suspension of your rights to use the
                    Services.
                  </span>
                </li>
              </ul>
              <p>&nbsp;</p>
              <ol start={10}>
                <li>
                  <strong> CONTRIBUTION LICENCE</strong>
                </li>
              </ol>
              <p>
                <span style={{ fontWeight: 400 }}>
                  You and Services agree that we may access, store, process, and
                  use any information and personal data you provide and your
                  choices (including settings).
                </span>
              </p>
              <p>&nbsp;</p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  By submitting suggestions or other feedback regarding the
                  Services, you agree that we can use and share such feedback
                  for any purpose without compensation.
                </span>
              </p>
              <p>&nbsp;</p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  We do not assert any ownership over your Contributions. You
                  retain full ownership of all your Contributions and any
                  intellectual property rights or other proprietary rights
                  associated with your Contributions. We are not liable for any
                  statements or representations in your Contributions provided
                  by you in any area of the Services. You are solely responsible
                  for your contributions to the services, and you expressly
                  agree to exonerate us from any responsibility and refrain from
                  any legal action against us regarding your contributions.
                </span>
              </p>
              <ol start={11}>
                <li>
                  <strong> GUIDELINES FOR REVIEWS</strong>
                </li>
              </ol>
              <p>
                <span style={{ fontWeight: 400 }}>
                  We may provide areas on the Services to leave reviews or
                  ratings. When posting a review, you must comply with the
                  following criteria: (1) you should have firsthand experience
                  with the person/entity being reviewed; (2) your reviews should
                  not contain offensive profanity or abusive, racist, offensive,
                  or hateful language; (3) your reviews should not contain
                  discriminatory references based on religion, race, gender,
                  national origin, age, marital status, sexual orientation, or
                  disability; (4) your reviews should not contain references to
                  illegal activity; (5) you should not be affiliated with
                  competitors if posting negative reviews; (6) you should not
                  make any conclusions as to the legality of conduct; (7) you
                  may not post any false or misleading statements; and (8) you
                  may not organise a campaign encouraging others to post
                  reviews, whether positive or negative.
                </span>
              </p>
              <p>&nbsp;</p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  We may accept, reject, or remove reviews at our sole
                  discretion. We are not obligated to screen reviews or delete
                  reviews, even if anyone considers reviews objectionable or
                  inaccurate. We do not endorse reviews and do not necessarily
                  represent our opinions or the views of any of our affiliates
                  or partners. We do not assume liability for any review or any
                  claims, liabilities, or losses resulting from any review. By
                  posting a review, you hereby grant to us a perpetual,
                  nonexclusive, worldwide, royalty-free, fully paid, assignable,
                  and sublicensable right and licence to reproduce, modify,
                  translate, transmit by any means, display, perform, and/or
                  distribute all content relating to the review.
                </span>
              </p>
              <ol start={12}>
                <li>
                  <strong> THIRD-PARTY WEBSITES AND CONTENT</strong>
                </li>
              </ol>
              <p>
                <span style={{ fontWeight: 400 }}>
                  The Services may contain (or you may be sent via the Site)
                  links to other websites ('Third-Party Websites') as well as
                  articles, photographs, text, graphics, pictures, designs,
                  music, sound, video, information, applications, software, and
                  other content or items belonging to or originating from third
                  parties ('Third-Party Content'). Such Third-Party Websites and
                  Third-Party Content are not investigated, monitored, or
                  checked for accuracy, appropriateness, or completeness by us,
                  and we are not responsible for any Third-Party Websites
                  accessed through the Services or any Third-Party Content
                  posted on, available through, or installed from the Services,
                  including the content, accuracy, offensiveness, opinions,
                  reliability, privacy practices, or other policies of or
                  contained in the Third-Party Websites or the Third-Party
                  Content. Inclusion of, linking to, or permitting the use or
                  installation of any Third-Party Websites or any Third-Party
                  Content does not imply our approval or endorsement.&nbsp;
                </span>
              </p>
              <p>&nbsp;</p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  If you decide to leave the Services and access the Third-Party
                  Websites or to use or install any Third-Party Content, you do
                  so at your own risk, and you should be aware these Legal Terms
                  no longer govern. You should review the applicable terms and
                  policies, including privacy and data gathering practices, of
                  any website you navigate from the Services or any applications
                  you use or install from the Services. Any purchases you make
                  through Third-Party Websites will be through other websites
                  and from other companies, and we take no responsibility
                  whatsoever in relation to such purchases which are exclusively
                  between you and the applicable third party. You agree and
                  acknowledge that we do not endorse the products or services
                  offered on Third-Party Websites, and you shall hold us
                  blameless from any harm caused by your purchase of such
                  products or services. Additionally, you shall hold us
                  blameless from any losses sustained by you or harm caused to
                  you relating to or resulting in any way from any Third-Party
                  Content or any contact with ThirdParty Websites.
                </span>
              </p>
              <p>&nbsp;</p>
              <ol start={13}>
                <li>
                  <strong> ADVERTISERS</strong>
                </li>
              </ol>
              <p>
                <span style={{ fontWeight: 400 }}>
                  We allow advertisers to display their advertisements and other
                  information in certain areas of the Services, such as sidebar
                  or banner advertisements. We simply provide the space to place
                  such advertisements and have no other relationship with
                  advertisers.
                </span>
              </p>
              <ol start={14}>
                <li>
                  <strong> SERVICES MANAGEMENT</strong>
                </li>
              </ol>
              <p>
                <span style={{ fontWeight: 400 }}>
                  We reserve the right, but not the obligation, to (1) monitor
                  the Services for violations of these Legal Terms; (2) take
                  appropriate legal action against anyone who, in our sole
                  discretion, violates the law or these Legal Terms, including
                  without limitation, reporting such user to law enforcement
                  authorities; (3) in our sole discretion and without
                  limitation, refuse, restrict access to, limit the availability
                  of, or disable (to the extent technologically feasible) any of
                  your Contributions or any portion thereof; (4) in our sole
                  discretion and without limitation, notice, or liability, to
                  remove from the Services or otherwise disable all files and
                  content that are excessive in size or are in any way
                  burdensome to our systems, and (5) otherwise manage the
                  Services in a manner designed to protect our rights and
                  property and to facilitate the proper functioning of the
                  Services.
                </span>
              </p>
              <p>&nbsp;</p>
              <ol start={15}>
                <li>
                  <strong> PRIVACY POLICY</strong>
                </li>
              </ol>
              <p>
                <span style={{ fontWeight: 400 }}>
                  We care about data privacy and security. By using the
                  Services, you agree to be bound by our Privacy Policy posted
                  on the Services, which is incorporated into these Legal Terms.
                  Please be advised the Services are hosted in Nigeria and the
                  United Kingdom. If you access the Services from any other
                  region of the world with laws or other requirements governing
                  personal data collection, use, or disclosure that differ from
                  applicable laws in Nigeria and the United Kingdom, then
                  through your continued use of the Services, you are
                  transferring your data to Nigeria and the United Kingdom. You
                  expressly consent to have your data transferred to and
                  processed in Nigeria and the United Kingdom.
                </span>
              </p>
              <p>&nbsp;</p>
              <ol start={16}>
                <li>
                  <strong> TERM AND TERMINATION</strong>
                </li>
              </ol>
              <p>
                <span style={{ fontWeight: 400 }}>
                  These Legal Terms shall remain full force and effect while you
                  use the Services. WITHOUT LIMITING ANY OTHER PROVISION OF
                  THESE LEGAL TERMS, WE RESERVE THE RIGHT TO, IN OUR SOLE
                  DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND
                  USE OF THE SERVICES (INCLUDING BLOCKING CERTAIN IP ADDRESSES),
                  TO ANY PERSON FOR ANY REASON OR NO REASON, INCLUDING WITHOUT
                  LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR
                  COVENANT CONTAINED IN THESE LEGAL TERMS OR OF ANY APPLICABLE
                  LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION
                  IN THE SERVICES OR DELETE ANY CONTENT OR INFORMATION YOU
                  POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.
                </span>
              </p>
              <p>&nbsp;</p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  Suppose we terminate or suspend your account for any reason.
                  In that case, you are prohibited from registering and creating
                  a new account under your name, a fake or borrowed name, or the
                  name of any third party, even if you may be acting on behalf
                  of the third party. In addition to terminating or suspending
                  your account, we reserve the right to take appropriate legal
                  action, including, without limitation, pursuing civil,
                  criminal, and injunctive redress.
                </span>
              </p>
              <p>&nbsp;</p>
              <ol start={17}>
                <li>
                  <strong> MODIFICATIONS AND INTERRUPTIONS</strong>
                </li>
              </ol>
              <p>
                <span style={{ fontWeight: 400 }}>
                  We reserve the right to change, modify, or remove the contents
                  of the Services at any time or for any reason at our sole
                  discretion without notice. However, we have no obligation to
                  update any information on our Services. We will not be liable
                  to you or any third party for any modification, price change,
                  suspension, or discontinuance of the Services.
                </span>
              </p>
              <p>&nbsp;</p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  We cannot guarantee the Services will be available at all
                  times. We may experience hardware, software, or other problems
                  or need to perform maintenance related to the Services,
                  resulting in interruptions, delays, or errors. We reserve the
                  right to change, revise, update, suspend, discontinue, or
                  otherwise modify the Services at any time or for any reason
                  without notice to you. You agree that we have no liability
                  whatsoever for any loss, damage, or inconvenience caused by
                  your inability to access or use the Services during any
                  downtime or discontinuance of the Services. Nothing in these
                  Legal Terms will be construed to obligate us to maintain and
                  support the Services or to supply any corrections, updates, or
                  releases in connection in addition to that.
                </span>
              </p>
              <p>&nbsp;</p>
              <ol start={18}>
                <li>
                  <strong> GOVERNING LAW</strong>
                </li>
              </ol>
              <p>
                <span style={{ fontWeight: 400 }}>
                  These Legal Terms shall be governed by and defined according
                  to the laws of Nigeria. LampHills and you irrevocably consent
                  that the courts of Nigeria shall have exclusive jurisdiction
                  to resolve any dispute that may arise in connection with these
                  Legal Terms.
                </span>
              </p>
              <p>&nbsp;</p>
              <ol start={19}>
                <li>
                  <strong> DISPUTE RESOLUTION</strong>
                </li>
              </ol>
              <p>&nbsp;</p>
              <p>
                <strong>Binding Arbitration</strong>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  Any dispute arising out of or in connection with these Legal
                  Terms, including any question regarding its existence,
                  validity, or termination, shall be referred to and finally
                  resolved by the International Commercial Arbitration Court
                  under the European Arbitration Chamber (Belgium, Brussels,
                  Avenue Louise, 146) according to the Rules of this ICAC,
                  which, as a result of referring to it, is considered as the
                  part of this clause. The number of arbitrators shall be four
                  (4). The seat, legal place, or arbitration shall be Abuja,
                  Nigeria. The language of the proceedings shall be English. The
                  governing law of these Legal Terms shall be the substantive
                  law of Nigeria.
                </span>
              </p>
              <p>&nbsp;</p>
              <p>
                <strong>Restrictions</strong>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  The Parties agree that arbitration shall be limited to the
                  Dispute between the Parties. To the full extent permitted by
                  law, (a) no arbitration shall be joined with any other
                  proceeding; (b) there is no right or authority for any Dispute
                  to be arbitrated on a class-action basis or to utilize
                  class-action procedures; and © there is no right or authority
                  for any Dispute to be brought in a purported representative
                  capacity on behalf of the general public or any other persons.
                </span>
              </p>
              <p>&nbsp;</p>
              <p>
                <strong>Exceptions to Arbitration</strong>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  The Parties agree that the following Disputes are not subject
                  to the above provisions concerning binding arbitration: (a)
                  any Disputes seeking to enforce or protect, or concerning the
                  validity of, any of the intellectual property rights of a
                  Party; (b) any Dispute related to, or arising from,
                  allegations of theft, piracy, invasion of privacy, or
                  unauthorized use; and (c) any claim for injunctive relief. If
                  this provision is found to be illegal or unenforceable, then
                  neither Party will elect to arbitrate any Dispute falling
                  within that portion of this provision found to be illegal or
                  unenforceable and such Dispute shall be decided by a court of
                  competent jurisdiction within the courts listed for
                  jurisdiction above, and the Parties agree to submit to the
                  personal jurisdiction of
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>that court.</span>
              </p>
              <p>&nbsp;</p>
              <ol start={20}>
                <li>
                  <strong> CORRECTIONS</strong>
                </li>
              </ol>
              <p>
                <span style={{ fontWeight: 400 }}>
                  There may be information on the Services containing
                  typographical errors, inaccuracies, or omissions, including
                  descriptions, pricing, availability, and other information. We
                  reserve the right to correct any errors, inaccuracies, or
                  omissions and to change or update the information on the
                  Services at any time without prior notice.
                </span>
              </p>
              <p>&nbsp;</p>
              <ol start={21}>
                <li>
                  <strong> DISCLAIMER</strong>
                </li>
              </ol>
              <p>
                <span style={{ fontWeight: 400 }}>
                  THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS.
                  YOU AGREE THAT YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE
                  RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL
                  WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE
                  SERVICES AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION,
                  THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                  PARTICULAR PURPOSE, AND NONINFRINGEMENT. WE MAKE NO WARRANTIES
                  OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE
                  SERVICES' CONTENT OR THE CONTENT OF ANY WEBSITES OR MOBILE
                  APPLICATIONS LINKED TO THE SERVICES AND WE WILL ASSUME NO
                  LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR
                  INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR
                  PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR
                  ACCESS TO AND USE OF THE SERVICES, (3) ANY UNAUTHORISED ACCESS
                  TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL
                  INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4)
                  ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE
                  SERVICES, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE
                  WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICES BY ANY
                  THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT
                  AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED
                  AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR
                  OTHERWISE MADE AVAILABLE VIA THE SERVICES. WE DO NOT WARRANT,
                  ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT
                  OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE
                  SERVICES, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE
                  APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING. WE
                  WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR
                  MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY
                  PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A
                  PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT,
                  YOU SHOULD USE YOUR BEST JUDGEMENT AND EXERCISE CAUTION WHERE
                  APPROPRIATE.
                </span>
              </p>
              <p>&nbsp;</p>
              <ol start={22}>
                <li>
                  <strong> LIMITATIONS OF LIABILITY</strong>
                </li>
              </ol>
              <p>
                <span style={{ fontWeight: 400 }}>
                  IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE
                  LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT,
                  CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE
                  DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR
                  OTHER DAMAGES ARISING FROM YOUR USE OF THE SERVICES, EVEN IF
                  WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                  NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR
                  LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF
                  THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE
                  AMOUNT PAID, IF ANY, BY YOU TO US. CERTAIN US STATE LAWS AND
                  INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED
                  WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES.
                  IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE
                  DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY
                  HAVE ADDITIONAL RIGHTS.
                </span>
              </p>
              <p>&nbsp;</p>
              <ol start={23}>
                <li>
                  <strong> INDEMNIFICATION</strong>
                </li>
              </ol>
              <p>
                <span style={{ fontWeight: 400 }}>
                  You agree to defend, indemnify, and hold us harmless,
                  including our subsidiaries, affiliates, and all of our
                  respective officers, agents, partners, and employees, from and
                  against any loss, damage, liability, claim, or demand,
                  including reasonable attorneys’ fees and expenses, made by any
                  third party due to or arising out of: (1) use of the Services;
                  (2) breach of these Legal Terms; (3) any breach of your
                  representations and warranties set forth in these Legal Terms;
                  (4) your violation of the rights of a third party, including
                  but not limited to intellectual property rights; or (5) any
                  overt harmful act toward any other user of the Services with
                  whom you connected via the Services. Notwithstanding the
                  preceding, we reserve the right, at your expense, to assume
                  the exclusive defence and control of any matter for which you
                  are required to indemnify us, and you agree to cooperate, at
                  your expense, with our defence of such claims. We will use
                  reasonable efforts to notify you of any such claim, action, or
                  proceeding subject to this indemnification upon becoming aware
                  of it.
                </span>
              </p>
              <p>&nbsp;</p>
              <ol start={24}>
                <li>
                  <strong> USER DATA</strong>
                </li>
              </ol>
              <p>
                <span style={{ fontWeight: 400 }}>
                  We will maintain certain data that you transmit to the
                  Services to manage the performance of the Services, as well as
                  data relating to your use of the Services. Although we perform
                  routine data backups, you are solely responsible for all data
                  that you transmit or that relates to any activity you have
                  undertaken using the Services. You agree that we shall have no
                  liability to you for any loss or corruption of any such data,
                  and you hereby waive any right of action against us arising
                  from any such loss or corruption of such data.
                </span>
              </p>
              <p>&nbsp;</p>
              <ol start={25}>
                <li>
                  <strong>
                    {" "}
                    ELECTRONIC COMMUNICATIONS, TRANSACTIONS AND SIGNATURES
                  </strong>
                </li>
              </ol>
              <p>
                <span style={{ fontWeight: 400 }}>
                  Visiting the Services, sending us emails, and completing
                  online forms constitute electronic communications. You consent
                  to receive electronic communications and agree that all
                  agreements, notices, disclosures, and other communications we
                  provide to you electronically, via email, and on the Services
                  satisfy any legal requirement that such communication be in
                  writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES,
                  CONTRACTS, ORDERS, AND OTHER RECORDS AND ELECTRONIC DELIVERY
                  OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR
                  COMPLETED BY US OR VIA THE SERVICES. You hereby waive any
                  rights or requirements under any statutes, regulations, rules,
                  ordinances, or other laws in any jurisdiction that require an
                  original signature or delivery or retention of non-electronic
                  records, or to payments or the granting of credits by any
                  means other than electronic means.
                </span>
              </p>
              <p>&nbsp;</p>
              <ol start={26}>
                <li>
                  <strong> MISCELLANEOUS</strong>
                </li>
              </ol>
              <p>
                <span style={{ fontWeight: 400 }}>
                  These Legal Terms and any policies or operating rules posted
                  by us on the Services or in respect to the Services constitute
                  the entire agreement and understanding between you and us. Our
                  failure to exercise or enforce any right or provision of these
                  Legal Terms shall not operate as a waiver of such right or
                  provision. These Legal Terms operate to the fullest extent
                  permissible by law. We may assign any or all of our rights and
                  obligations to others at any time. We shall not be responsible
                  or liable for any loss, damage, delay, or failure to act
                  caused by any cause beyond our reasonable control. Suppose any
                  provision or part of a provision of these Legal Terms is
                  determined to be unlawful, void, or unenforceable. In that
                  case, that provision or part of the provision is deemed
                  severable from these Legal Terms and does not affect the
                  validity and enforceability of any remaining provisions. No
                  joint venture, partnership, employment, or agency relationship
                  is created between you and us due to these Legal Terms or use
                  of the Services. You agree that these Legal Terms will not be
                  construed against us by having drafted them. You hereby waive
                  any defenses you may have based on the electronic form of
                  these Legal Terms and the lack of signing by the parties
                  hereto to execute these Legal Terms.
                </span>
              </p>
              <p>&nbsp;</p>
              <ol start={27}>
                <li>
                  <strong> CONTACT US</strong>
                </li>
              </ol>
              <p>
                <span style={{ fontWeight: 400 }}>
                  To resolve a complaint regarding the Services or to receive
                  further information regarding the use of the Services, please
                  contact us at LampHills, Abuja, Nigeria
                </span>
              </p>
              <p>
                <br />
                <br />
              </p>
            </>
          </div>
        </div>
      </div>
      {/* Content  */}
    </div>
  );
};

export default TermsAndCondtions;
