import { Link } from "react-router-dom";
import { IList } from "../../../utils/generalType";
import ContactComp from "../sharedLandingcomponents/Contact_Comp";
import MotionCompGeneral from "../sharedLandingcomponents/Motion_Comp";
import PagerHeaderComp from "../sharedLandingcomponents/PagesHeaderComp";
import "../sharedLandingcss/Site.css";
import "../sharedLandingcss/Site_Animate.css";

const ContactPage = () => {
  const contacts: IList[] = [
    {
      title: " Call Us",
      subTitle: "+234 704 390 7787",
      content: (
        <>
          <a href="tel:+234 704 390 7787"> +234 704 390 7787</a>
        </>
      ),
      image: `${process.env.PUBLIC_URL + "/images/contact/contact1.jpeg"}`,
    },
    {
      title: " Make A Quote",
      subTitle: "contact@lamphills.com",
      content: (
        <>
          <a href="mailto:contact@lamphills.com"> contact@lamphills.com</a>
        </>
      ),
      image: `${process.env.PUBLIC_URL + "/images/contact/contact2.jpeg"}`,
    },
    {
      title: " Work Station",
      subTitle: "10 Mast Street, Barking, London, United Kingdom",
      image: `${process.env.PUBLIC_URL + "/images/contact/contact3.jpeg"}`,
    },
  ];
  return (
    <div className="w3-white w3-col" style={{ minHeight: "400px" }}>
      <PagerHeaderComp
        title="Contact Us"
        subTitle="Ready to Light Up Your Brand's Future?"
      ></PagerHeaderComp>

      <div className="w3-col contactContainer">
        {contacts.map((item: IList, index: number) => (
          <div className="w3-col w3-col l4 m6 s12" style={{ padding: "15px" }}>
            <div className="w3-col w3-card-0 w3-border w3-round-large w3-white w3-center">
              <div
                className="w3-col"
                style={{
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                <img
                  className="w3-margin-top w3-margin-bottom"
                  src={item.image}
                  alt="image_"
                  style={{
                    maxWidth: "100%",
                    height: "150px",
                  }}
                />
              </div>

              <h4 className="fontJostRegular w3-medium">{item.title}</h4>
              <p className="fontJostBold w3-large">
                {item.content || item.subTitle}
              </p>
            </div>
          </div>
        ))}
      </div>

      <div className="w3-col">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2481.901575124836!2d0.08056847662162038!3d51.533365071819176!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8a60d0ac95e61%3A0x957f72231a1a1bdc!2s10%20Mast%20St%2C%20Barking%20IG11%207RN%2C%20UK!5e0!3m2!1sen!2sng!4v1713533779952!5m2!1sen!2sng"
          width="100%"
          height="650"
          style={{ border: "0" }}
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Google Map Embed"
        ></iframe>
      </div>

      {/* Content  */}
      <div className="w3-white otherContentBg w3-col">
        {/* Motions for Traffic */}
        <div className="w3-hide-small w3-hide-medium">
          <MotionCompGeneral
            showMoveLeft={true}
            showMoveRight={true}
            showRotateRight={true}
            rotateRightImg={`${
              process.env.PUBLIC_URL + "/images/homePage/spec-el-2.png"
            }`}
          ></MotionCompGeneral>
        </div>
        <div className="w3-content w3-padding">
          <p className="fontJostRegular w3-large">
            <b>We're just a message away from starting your transformation.</b>
          </p>
          <p className="fontJostRegular w3-medium">
            Have questions or ready to take the first step towards a brighter
            brand image? Reach out to us! Whether it's for a quick inquiry or to
            schedule a detailed consultation, our contacts is eager to hear from
            you. Let's start crafting your success story today.
          </p>
        </div>
      </div>
      {/* Content  */}

      {/* Contact Us */}
      <ContactComp></ContactComp>
      {/* Contact Us */}
    </div>
  );
};

export default ContactPage;
