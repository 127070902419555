import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { IList } from "../../../utils/generalType";
import "../sharedLandingcss/Site.css";
import "../sharedLandingcss/Site_Animate.css";
import MotionCompGeneral from "./Motion_Comp";
import Slide from "react-reveal/Slide";

const testimony: IList[] = [
  {
    title: "Adeoluwa Adewale",
    subTitle: "CEO",
    caption:
      "Working with LampHills was a game-changer for our brand. Their approach to reputation management helped us connect with our audience on a deeper level, and the results were beyond our expectations. Their team's dedication to our success was evident in every step of the process. LampHills is not just a service provider but a true partner in building a stronger, more authentic brand.",
    image: `${process.env.PUBLIC_URL + "/images/testimony/test1.png"}`,
  },
  {
    title: "Chika Okonkwo",
    subTitle: "Marketing Manager",
    caption:
      "Choosing LampHills was one of the best decisions we made for our company. Their team's professionalism and commitment to our success were evident from day one. They took the time to understand our unique needs and tailored their strategies accordingly. Thanks to LampHills, we've seen a significant improvement in our reputation and brand visibility. We couldn't be happier with the results!",
    image: `${process.env.PUBLIC_URL + "/images/testimony/test2.png"}`,
  },
  {
    title: "Rebecca Adams",
    subTitle: "CEO",
    caption:
      "LampHills exceeded my expectations in every way. Their collaborative approach and dedication to understanding my brand's values and needs truly set them apart. They helped me overcome a challenging crisis and provided invaluable insights into sustainability. LampHills is a trusted partner committed to our long-term success.",
    image: `${process.env.PUBLIC_URL + "/images/testimony/test3.png"}`,
  },
  {
    title: "Evelyn Udoh",
    subTitle: "Event Planner",
    caption:
      "I can't speak highly enough of LampHills and their team. Their expertise in reputation management and crisis communication is unparalleled. When faced with a public relations dilemma, they stepped in with confidence and poise, guiding me through the process professionally and gracefully. They were true allies in protecting and enhancing my brand's reputation.",
    image: `${process.env.PUBLIC_URL + "/images/testimony/test4.png"}`,
  },
];
export const TestimonyComp = () => {
  return (
    <>
      {/* Testimony */}
      <div className="w3-white testimonyBg  w3-col ">
        {/* Motions for Traffic */}
        <Slide up>
          <div className="w3-hide-small w3-hide-medium">
            <MotionCompGeneral
              showMoveLeft={true}
              showMoveRight={true}
              showRotateRight={true}
              rotateRightImg={`${
                process.env.PUBLIC_URL + "/images/homePage/spec-el-2.png"
              }`}
            ></MotionCompGeneral>
          </div>
        </Slide>
        <Slide up>
          <div className="w3-content">
            <Swiper
              // install Swiper modules
              modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
              navigation
              pagination={{ clickable: true }}
              onSlideChange={() => console.log("slide change")}
              onSwiper={(swiper) => console.log(swiper)}
              spaceBetween={20}
              slidesPerView={1}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                // when window width is >= 600x
                600: {
                  slidesPerView: 1,
                  spaceBetween: 30,
                },
                // when window width is >= 840px
                840: {
                  slidesPerView: 1,
                  spaceBetween: 40,
                },
              }}
            >
              {testimony.map((item: IList, index: number) => (
                <SwiperSlide>
                  <div className="w3-col w3-center">
                    <img
                      className="w3-margin-top w3-circle w3-margin-bottom"
                      src={item.image}
                      alt="image_"
                      style={{ maxWidth: "100%", width: "100px" }}
                    />

                    <div className="w3-col w3-center">
                      <div>
                        <h2 className="fontJostBold w3-xxlarge">
                          {item.title}
                        </h2>
                      </div>
                      <div>
                        <p className="fontJostMedium w3-large">
                          {" "}
                          {item.subTitle}{" "}
                        </p>
                      </div>
                      <div>
                        <p className="fontJostRegular w3-medium">
                          {item.caption}
                        </p>
                      </div>
                    </div>

                    <div
                      className="w3-col w3-center"
                      style={{ height: "60px" }}
                    >
                      <br />
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </Slide>
      </div>
      {/* Testimony */}
    </>
  );
};

export default TestimonyComp;
