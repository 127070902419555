import "../sharedLandingcss/Site.css";
import "../sharedLandingcss/Site_Animate.css";
import HeroComp from "../sharedLandingcomponents/Hero_Comp";
import MissionComp from "../sharedLandingcomponents/Mission_Comp";
import CoreValueComp from "../sharedLandingcomponents/Core_Value_Comp";
import FaqComp from "../sharedLandingcomponents/Faq_Comp";
import ContactComp from "../sharedLandingcomponents/Contact_Comp";
import ServiceSlide from "../sharedLandingcomponents/Service_Slide_Comp";
import TrustedBrandComp from "../sharedLandingcomponents/brand_info";
import { TestimonyComp } from "../sharedLandingcomponents/Testimony_Comp";
import CounterComp from "../sharedLandingcomponents/Counter_Comp";
import BlogComp from "../sharedLandingcomponents/Blog_Comp";

export const HomePage = () => {
  return (
    <>
      <div
        className="w3-white w3-col divWrapper"
        style={{ minHeight: "400px" }}
      >
        {/* Hero Section */}
        <HeroComp></HeroComp>
        {/* End Hero Section */}

        {/* SUB Hero Section */}
        {/* <HomeSubHero></HomeSubHero> */}
        {/* SUB Hero Section */}

        {/* Our Service */}
        <ServiceSlide></ServiceSlide>
        {/* Our Service */}

        {/* Mission Statement */}
        <MissionComp></MissionComp>
        {/*  Mission Statement */}

        {/* Core Value  */}
        <CoreValueComp></CoreValueComp>
        {/* Core Value  */}

        {/* Blog Comp */}
        <BlogComp></BlogComp>
        {/* Blog Comp */}

        {/* Counter  */}
        <CounterComp></CounterComp>
        {/* Counter  */}

        {/* Testimony */}
        <TestimonyComp></TestimonyComp>
        {/* Testimony */}

        {/* FAQ */}
        <FaqComp></FaqComp>
        {/*  faq */}

        {/* Trusted Brands */}
        <TrustedBrandComp></TrustedBrandComp>
        {/* Trusted Brands */}

        {/* Contact Us */}
        <ContactComp></ContactComp>
        {/* Contact Us */}
      </div>
    </>
  );
};

export default HomePage;
