import { Collapse, CollapseProps } from "antd";
import "../sharedLandingcss/Site.css";
import "../sharedLandingcss/Site_Animate.css";
import Slide from "react-reveal/Slide";

export const FaqComp = () => {
  const faq: CollapseProps["items"] = [
    {
      key: "1",
      label: "What industries does LampHills specialize in?",
      children: (
        <p>
          LampHills has a diverse team with experience across a wide range of
          industries. We can tailor our approach to your needs, regardless of
          your industry.
        </p>
      ),
    },
    {
      key: "2",
      label: "What are some examples of the types of content you create?",
      children: (
        <p>
          We create a variety of content formats to meet your needs, including
          press releases, blog posts, social media content, website copy, and
          speeches.
        </p>
      ),
    },
    {
      key: "3",
      label: "What are the benefits of working with a PR firm like LampHills?",
      children: (
        <p>
          We offer a team of experienced professionals who can help you develop
          and implement the best PR strategies. We also have the resources and
          expertise to secure media coverage, manage your online reputation, and
          measure the success of your campaigns.
        </p>
      ),
    },
    {
      key: "4",
      label:
        "How do you measure the success of your public relations campaigns?",
      children: (
        <p>
          We measure success through key performance indicators (KPIs),
          including media coverage, brand visibility, audience engagement,
          sentiment analysis, and, ultimately, the impact on our clients'
          objectives.
        </p>
      ),
    },
    {
      key: "5",
      label:
        "What sets LampHills Solutions apart from other public relations agencies?",
      children: (
        <p>
          At LampHills, we distinguish ourselves through our client-centric
          approach, innovative strategies, transparent communication, and
          commitment to delivering exceptional results in 90 days.
        </p>
      ),
    },
    {
      key: "6",
      label:
        "Do you offer customized packages or services tailored to specific needs?",
      children: (
        <p>
          Yes, we understand that every client is unique, and we offer
          customized packages and services tailored to meet specific objectives
          and budget requirements. Whether you need comprehensive PR support or
          assistance with specific projects, we can create a solution that fits
          your needs.
        </p>
      ),
    },
    {
      key: "7",
      label: "How can I get started with LampHills?",
      children: (
        <p>
          Getting started is easy! Simply reach out to us through our contact
          form, and one of our representatives will contact you to discuss your
          needs and objectives and how we can help you achieve your goals
          effectively and efficiently.
        </p>
      ),
    },
  ];

  return (
    <>
      {/* FAQ */}
      <Slide up>
        <div className="FAQBg w3-col">
          <div className="w3-content w3-padding">
            <div className=" w3-center">
              <h2 className="fontJostBold w3-xxlarge">FAQ</h2>
            </div>
            <div>
              <Collapse accordion items={faq} />
            </div>
          </div>
        </div>
      </Slide>
      {/*  faq */}
    </>
  );
};

export default FaqComp;
