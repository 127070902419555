import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { IList } from "../../../utils/generalType";
import "../sharedLandingcss/Site.css";
import "../sharedLandingcss/Site_Animate.css";
import MotionCompGeneral from "./Motion_Comp";
import Slide from "react-reveal/Slide";

export const BlogComp = () => {
  const news: IList[] = [
    {
      title: "Brand Advertising: Elevating Your Brand Awareness",
      subTitle:
        "No matter how great your product or service might be, it’s useless if the target consumers are unaware…",
      image: `${process.env.PUBLIC_URL + "/images/blog/blog3.jpg"}`,
      link: "https://blog.lamphills.com/branding/brand-advertising/",
    },
    {
      title: "Social Media Etiquette: Best Practices",
      subTitle:
        "You know that saying that the internet never forgets, right? Well, it’s true, and that’s why you should… ",
      image: `${process.env.PUBLIC_URL + "/images/blog/blog2.jpg"}`,
      link: "https://blog.lamphills.com/social-media/social-media-etiquette/",
    },
    {
      title: "HOW TO REPORT A BLACKMAIL: All You Need To Know",
      subTitle:
        "Encrypted threats and veiled accusations: a digital extortion attempt predicated on a previous error. Blackmail is a frightening…",
      image: `${process.env.PUBLIC_URL + "/images/blog/blog1.jpg"}`,
      link: "https://blog.lamphills.com/crisis-management/how-to-report-a-blackmail/",
    },
  ];
  return (
    <>
      {/* Our News and blog */}
      <div className="w3-white ourNewsBg w3-col ">
        {/* Motions for Traffic */}
        <div className="w3-hide-small w3-hide-medium">
          <MotionCompGeneral
            showMoveLeft={true}
            showMoveRight={true}
            showRotateRight={true}
            rotateRightImg={`${
              process.env.PUBLIC_URL + "/images/homePage/spec-el-2.png"
            }`}
          ></MotionCompGeneral>
        </div>
        <div className="w3-content">
          <Slide up>
            <div className="w3-col w3-center">
              {/* <p className="fontJostRegular w3-large"> Our blog </p> */}
              <div>
                <h2 className="fontJostBold w3-xxlarge">News & Events </h2>
              </div>
            </div>
          </Slide>
          <Slide up>
            <div className="w3-col">
              <Swiper
                // install Swiper modules
                modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
                navigation
                onSlideChange={() => console.log("slide change")}
                onSwiper={(swiper) => console.log(swiper)}
                spaceBetween={15}
                slidesPerView={3}
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                  },
                  // when window width is >= 600x
                  600: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                  },
                  // when window width is >= 840px
                  840: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                  },
                }}
              >
                {news.map((item: IList, index: number) => (
                  <SwiperSlide>
                    <div className="w3-col" style={{ padding: "15px" }}>
                      <div className="w3-col w3-padding">
                        <img
                          className="w3-margin-top w3-margin-bottom w3-round-xlarge"
                          src={item.image}
                          alt="image_"
                          style={{ maxWidth: "100%" }}
                        />
                        <h4 className="fontJostBold w3-large">{item.title}</h4>
                        <p className="fontJostRegular w3-medium">
                          {item.subTitle}
                        </p>

                        <p>
                          <a
                            className="fontJostMedium w3-medium"
                            href={item.link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Know More
                          </a>
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </Slide>
        </div>
      </div>
      {/* Our News and blog  */}
    </>
  );
};

export default BlogComp;
