import "../sharedLandingcss/Site.css";
import "../sharedLandingcss/Site_Animate.css";
import PagerHeaderComp from "../sharedLandingcomponents/PagesHeaderComp";
import ServiceCard from "../sharedLandingcomponents/Service_Card_Comp";

const ServicesPage = () => {
  return (
    <div className="w3-white w3-col" style={{ minHeight: "400px" }}>
      <PagerHeaderComp title="Our Services"></PagerHeaderComp>
      <ServiceCard></ServiceCard>
    </div>
  );
};

export default ServicesPage;
